import { AccountType } from "@/__generated__/graphql";
import type {
  Account,
  PostPaidAccount,
  PrePaidAccount,
  PrePaidService,
} from "@/components/machines/userAccountMachine/types";

function isPrePaidMobileService(service: {
  __typename: string;
}): service is PrePaidService {
  return service.__typename === "PrePaidMobileService";
}

function isPostPaidAccount(account: Account): account is PostPaidAccount {
  return account.type === AccountType.Postpaid;
}

function isPrePaidAccount(account: Account): account is PrePaidAccount {
  return account.type === AccountType.Prepaid;
}

export { isPostPaidAccount, isPrePaidAccount, isPrePaidMobileService };
