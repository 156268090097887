/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { assertEvent, assign, createMachine } from "xstate";
import config from "@/config";

const cookiesMachine = createMachine(
  {
    context: {
      canShowNewUserInfoPopup: null,
      cookieConsent: null,
      hasSignedInAlready: false,
    },
    id: "cookieConsent",
    initial: "CheckingIfUserHasPreviouslyLoggedIn",
    states: {
      CheckingIfUserHasPreviouslyLoggedIn: {
        invoke: {
          src: "checkIfUserHasLoggedInBefore",
        },
        on: {
          ESIM_FLOW: {
            actions: "setHasAcceptedCookies",
            target: "RedirectingToLoginScreen",
          },
          NEW_USER: {
            target: "CheckingLocalStorage",
          },
          USER_HAS_PREVIOUSLY_LOGGED_IN: {
            actions: "assignCanShowNewUserPopupToNotShowPopupsAgain",
            target: "RedirectingToLoginScreen",
          },
          USER_IS_SIGNED_IN: {
            actions: "assignCanShowNewUserPopupToNotShowPopupsAgain",
            target: "NotShowingUserPopup",
          },
        },
      },
      CheckingLocalStorage: {
        always: [
          {
            guard: "isCookieConsentFound",
            target: "CookieConsentObtained",
          },
          {
            target: "ShowingCookieConsentPopup",
          },
        ],
        description: "Checks local storage for cookie consent key.",
      },
      CookieConsentObtained: {
        always: [
          {
            guard: "isUserEligibleForNewUserPopup",
            target: "ShowingNewUserPopup",
          },
          {
            target: "NotShowingUserPopup",
          },
        ],
        description:
          "Stores the user's cookie consent and decides if the new user popup should be shown.",
      },
      NotShowingUserPopup: {
        description:
          "The user has either completed registration or logged in previously or disabled the registration popup.",
        type: "final",
      },
      RedirectingToLoginScreen: {
        description:
          "Redirects the user to the login screen as they have previously signed in.",
        entry: "redirectToLoginScreen",
        target: "NotShowingUserPopup",
      },
      ShowingCookieConsentPopup: {
        description:
          "Shows the cookie consent popup to the user to accept or deny cookies.",
        on: {
          UPDATE_CONSENT: {
            actions: "updateCookieConsent",
            target: "CookieConsentObtained",
          },
        },
      },
      ShowingNewUserPopup: {
        description:
          "Shows the registration popup to new users on their first visit after cookie consent.",
        on: {
          UPDATE_NEW_USER_POPUP: {
            actions: "updateCanShowNewUserPopup",
            target: "NotShowingUserPopup",
          },
        },
      },
    },
    types: {
      context: {} as CookiesMachineContext,
      events: {} as CookiesMachineEvents,
    },
  },
  {
    actions: {
      assignCanShowNewUserPopupToNotShowPopupsAgain: assign({
        canShowNewUserInfoPopup: () => {
          localStorage.setItem("canShowNewUserPopup", "false");

          return false;
        },
      }),
      setHasAcceptedCookies: assign({
        cookieConsent: () => {
          localStorage.setItem("hasAcceptedCookies", "true");

          return true;
        },
      }),
      updateCanShowNewUserPopup: assign({
        canShowNewUserInfoPopup: ({ event }) => {
          assertEvent(event, "UPDATE_NEW_USER_POPUP");
          localStorage.setItem(
            "canShowNewUserPopup",
            (event.popup === "Show").toString(),
          );

          return event.popup === "Show";
        },
      }),
      updateCookieConsent: assign({
        cookieConsent: ({ event }) => {
          assertEvent(event, "UPDATE_CONSENT");
          localStorage.setItem(
            "hasAcceptedCookies",
            (event.consent === "Accepted").toString(),
          );

          return event.consent === "Accepted";
        },
      }),
    },
    actors: {},
    delays: {},
    guards: {
      isCookieConsentFound: () => {
        const cookieConsent = localStorage.getItem("hasAcceptedCookies");

        return config.app.isNativeApp || cookieConsent !== null;
      },
      isEsimPurchaseFlow: () => {
        const { pathname } = window.location;

        return pathname.includes("/buy/esim");
      },
      isUserEligibleForNewUserPopup: () => {
        const canShowNewUserPopup = localStorage.getItem("canShowNewUserPopup");

        return canShowNewUserPopup === "true" || canShowNewUserPopup === null;
      },
    },
  },
);

interface CookiesMachineContext {
  cookieConsent: boolean | null;
  canShowNewUserInfoPopup: boolean | null;
  hasSignedInAlready: boolean;
}

export type Consent = "Accepted" | "Denied";

export type Popup = "Show" | "Skip";

export type CookiesMachineEvents =
  | { consent: Consent; type: "UPDATE_CONSENT" }
  | { popup: Popup; type: "UPDATE_NEW_USER_POPUP" }
  | { type: "ESIM_FLOW" }
  | { type: "NEW_USER" }
  | { type: "USER_HAS_PREVIOUSLY_LOGGED_IN" }
  | { type: "USER_IS_SIGNED_IN" };

export default cookiesMachine;
