import type { Alignment, MaxWidthType } from "@jt/ui";
import { BackToTop, Container, Hero, SideMenu } from "@jt/ui";
import Head from "next/head";
import Link from "next/link";
import type { JSX } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Capacitor } from "@capacitor/core";
import PageAnimation from "@/components/PageAnimation";
import useSelectedServiceType from "@/components/machines/userAccountMachine/useSelectedServiceType";
import useIosNotch from "@/components/layouts/app/useIosNotch";
import Chat from "@/components/layouts/Chat";
import IframedChat from "@/components/layouts/IframedChat";
import DesktopFooter from "@/components/layouts/DesktopFooter";
import DesktopFooterBar from "@/components/layouts/DesktopFooterBar";
import DesktopHeader from "@/components/layouts/DesktopHeader";
import Feedback from "@/components/layouts/Feedback";
import AppHeader from "@/components/layouts/app/AppHeader";
import UserAccountMachineContext from "@/components/machines/userAccountMachine/UserAccountMachineContext";
import type { AccountType } from "@/components/machines/userAccountMachine/accountTypeHook";
import useAccountType from "@/components/machines/userAccountMachine/accountTypeHook";
import { onlyPostpaidMenus, postpaidMenus, prepaidMenus } from "@/menu";
import config from "@/config";

interface TemplateContextType {
  align: Alignment;
  hasButtonBack: boolean;
  hasContainer: boolean;
  hasHero: boolean;
  hasNav: boolean;
  hasSearch: boolean;
  hasSideMenu: boolean;
  hasTransition: boolean;
  maxWidth: MaxWidthType;
  title: string;
}

interface TemplateDispatchContextType {
  setAlign: (align: Alignment) => void;
  setHasButtonBack: (hasButtonBack: boolean) => void;
  setHasContainer: (hasContainer: boolean) => void;
  setHasHero: (hasHero: boolean) => void;
  setHasNav: (hasNav: boolean) => void;
  setHasSearch: (hasSearch: boolean) => void;
  setHasSideMenu: (hasSideMenu: boolean) => void;
  setHasTransition: (transition: boolean) => void;
  setMaxWidth: (maxWidth: MaxWidthType) => void;
  setTitle: (title: string) => void;
}

const TemplateContext = createContext<TemplateContextType | undefined>(
  undefined,
);
const TemplateDispatchContext = createContext<
  TemplateDispatchContextType | undefined
>(undefined);

function TemplateProvider({ children }: { children: JSX.Element }) {
  const [title, setTitle] = useState<string>("");
  const [align, setAlign] = useState<Alignment>("center");
  const [hasButtonBack, setHasButtonBack] = useState<boolean>(true);
  const [hasContainer, setHasContainer] = useState<boolean>(true);
  const [hasHero, setHasHero] = useState<boolean>(true);
  const [hasNav, setHasNav] = useState<boolean>(true);
  const [hasSearch, setHasSearch] = useState<boolean>(false);
  const [hasSideMenu, setHasSideMenu] = useState<boolean>(true);
  const [maxWidth, setMaxWidth] = useState<MaxWidthType>("widest");
  const [hasTransition, setHasTransition] = useState<boolean>(true);

  const contextValue = useMemo(
    () => ({
      align,
      hasButtonBack,
      hasContainer,
      hasHero,
      hasNav,
      hasSearch,
      hasSideMenu,
      hasTransition,
      maxWidth,
      title,
    }),
    [
      align,
      hasButtonBack,
      hasContainer,
      hasHero,
      hasNav,
      hasSearch,
      hasSideMenu,
      hasTransition,
      maxWidth,
      title,
    ],
  );
  const dispatchContextValue = useMemo(
    () => ({
      setAlign,
      setHasButtonBack,
      setHasContainer,
      setHasHero,
      setHasNav,
      setHasSearch,
      setHasSideMenu,
      setHasTransition,
      setMaxWidth,
      setTitle,
    }),
    [
      setAlign,
      setHasButtonBack,
      setHasContainer,
      setHasHero,
      setHasNav,
      setHasSearch,
      setHasSideMenu,
      setHasTransition,
      setMaxWidth,
      setTitle,
    ],
  );

  return (
    <TemplateContext.Provider value={contextValue}>
      <TemplateDispatchContext.Provider value={dispatchContextValue}>
        {children}
      </TemplateDispatchContext.Provider>
    </TemplateContext.Provider>
  );
}

function useTemplate({ title }: { title?: string } = {}) {
  const templateContext = useContext(TemplateContext);
  const templateDispatchContext = useContext(TemplateDispatchContext);

  if (templateContext === undefined) {
    throw new Error(
      "useTemplateContext must be used within a TemplateProvider",
    );
  }
  if (templateDispatchContext === undefined) {
    throw new Error(
      "useTemplateDispatchContext must be used within a TemplateProvider",
    );
  }

  useEffect(
    function updateTitle() {
      if (
        title !== undefined &&
        title.length > 0 &&
        title !== templateContext.title
      ) {
        templateDispatchContext.setTitle(title);
      }
    },
    [title, templateContext.title, templateDispatchContext],
  );

  return {
    ...templateContext,
    ...templateDispatchContext,
  };
}
interface TemplateProperties {
  children: JSX.Element;
}

function Template({ children }: TemplateProperties) {
  const accountType = useAccountType();
  const serviceType = useSelectedServiceType();
  const {
    align,
    hasButtonBack,
    hasContainer,
    hasHero,
    hasNav,
    hasSearch,
    hasSideMenu,
    hasTransition,
    maxWidth,
    title,
  } = useTemplate();
  const [paddingLeftForSideMenu, setPaddingLeftForSideMenu] = useState<string>(
    hasSideMenu ? "md:pl-[276px]" : "",
  );
  const userState = UserAccountMachineContext.useSelector((snapshot) => {
    return snapshot.context;
  });

  const { selectedComverseAccountId, selectedService } = userState;

  function onMenuExpand(shouldExpand: boolean): void {
    if (shouldExpand) {
      setPaddingLeftForSideMenu("md:pl-[276px]");
    } else {
      setPaddingLeftForSideMenu("md:pl-24");
    }
  }

  useEffect(
    function syncPaddingIfSideMenuIsPresent() {
      if (hasSideMenu) {
        setPaddingLeftForSideMenu("md:pl-[276px]");
      } else {
        setPaddingLeftForSideMenu("");
      }
    },
    [hasSideMenu],
  );

  const { isNativeApp } = config.app;

  useIosNotch();

  return (
    <div
      className={`relative min-h-screen select-none ${
        isNativeApp ? "flex flex-col" : ""
      }`}
    >
      <Head>
        <title>{`JT Global - ${title}`}</title>
      </Head>
      {hasNav ? <DesktopHeader /> : null}
      {isNativeApp && hasNav ? (
        <AppHeader
          hasButtonBack={hasButtonBack}
          hasHeaderTitle={hasHero}
          hasSearch={hasSearch}
          title={title}
        />
      ) : null}

      <div
        className={`${
          isNativeApp ? "flex-grow md:mb-auto" : ""
        } flex flex-col justify-between md:h-[calc(100vh-124px)]`}
      >
        <main
          className={`relative z-10 flex grow flex-col @container ${paddingLeftForSideMenu}`}
        >
          {hasHero ? (
            <Hero
              accountType={serviceType === "Prepaid" ? "prepaid" : "postpaid"}
              caption={`${
                serviceType === "Prepaid"
                  ? selectedService?.phoneNumber
                  : `Account Number: ${selectedComverseAccountId ?? "..."}`
              }`}
              isApp={isNativeApp}
              title={title}
            />
          ) : null}

          <div className="flex-grow bg-neutral-75">
            {hasContainer ? (
              <Container align={align} maxWidth={maxWidth}>
                <PageAnimation hasTransition={hasTransition}>
                  {children}
                </PageAnimation>
              </Container>
            ) : (
              <PageAnimation hasTransition={hasTransition}>
                {children}
              </PageAnimation>
            )}
          </div>
        </main>
        <Feedback />
        {Capacitor.getPlatform() === "ios" ? <IframedChat /> : <Chat />}
        {hasNav ? (
          <footer className={`w-full @container ${paddingLeftForSideMenu}`}>
            <BackToTop />
            <DesktopFooter />
            <DesktopFooterBar />
          </footer>
        ) : (
          <footer />
        )}
      </div>
      {hasSideMenu ? (
        <SideMenu
          LinkComponent={Link}
          alternativeHomePath="/user/"
          data={getMenu(accountType)}
          isApp={isNativeApp}
          onMenuExpand={onMenuExpand}
          shouldPathExactMatch={accountType === "SinglePrepaid"}
        />
      ) : null}
    </div>
  );
}

function getMenu(accountType: AccountType) {
  switch (accountType) {
    case "MultiplePrepaid": {
      return prepaidMenus;
    }

    case "SinglePrepaid": {
      return prepaidMenus;
    }

    case "OnlyPostpaid": {
      return onlyPostpaidMenus;
    }

    case "PreAndPostPaid": {
      return postpaidMenus;
    }

    default: {
      return postpaidMenus;
    }
  }
}

export { TemplateProvider, useTemplate };

export default Template;
