import { useState, type ReactNode, useEffect } from "react";
import { useRouter } from "next/router";
import { useQueryClient } from "@tanstack/react-query";
import {
  type AnyEventObject,
  fromCallback,
  fromPromise,
  type Snapshot,
} from "xstate";
import { createActorContext } from "@xstate/react";
import type { UserAccountMachineEvents } from "./types";
import useAuth from "@/useAuth";
import { useGraphQLContext } from "@/components/contexts/GraphQLContext";
import userAccountMachine from "@/components/machines/userAccountMachine/userAccountMachine";
import {
  accountsQuery,
  removeAccountMutation,
  servicesQuery,
} from "@/components/machines/userAccountMachine/userAccountQueriesAndMutations";
import { checkIsNonEmptyString } from "@/typeGuards";
import config from "@/config";
import { AccountType } from "@/__generated__/graphql";
import { useMachineUtil } from "@/components/contexts/MachineUtil";

const UserAccountMachineContext = createActorContext(userAccountMachine);

function checkAndRedirectToLoginOrHomepage(url: string) {
  const isPrivateUrl = url.toLocaleLowerCase().includes("/user/");
  const isRedirectUrl = url.toLocaleLowerCase().includes("redirect=");

  switch (true) {
    case !config.app.isNativeApp && isPrivateUrl && !isRedirectUrl: {
      window.location.href = `/?redirect=${url}`;
      break;
    }

    case config.app.isNativeApp && isPrivateUrl: {
      window.location.href = "/";
      break;
    }

    default: {
      break;
    }
  }
}

function UserAccountMachineLogicComponent({
  children,
}: {
  children: ReactNode;
}) {
  const [userAccountMachineSnapshot, setUserAccountMachineSnapshot] =
    useState<Snapshot<unknown>>();
  const [isSnapshotLoaded, setIsSnapshotLoaded] = useState<boolean>(false);
  const { user, isLoading } = useAuth();
  const router = useRouter();
  const { inspect } = useMachineUtil();

  useEffect(() => {
    const json = localStorage.getItem("userAccountState");

    if (json !== null) {
      setUserAccountMachineSnapshot(JSON.parse(json) as Snapshot<unknown>);
    }
    setIsSnapshotLoaded(true);
  }, []);

  const { graphqlClient } = useGraphQLContext();
  const queryClient = useQueryClient();

  if (!isSnapshotLoaded || isLoading) return null;

  const manageUserAccountMachineImplementation = userAccountMachine.provide({
    actors: {
      checkForServiceIdsInUrl: fromCallback(
        ({
          sendBack,
        }: {
          sendBack: (event: UserAccountMachineEvents) => void;
        }) => {
          const queryServiceIds = router.query.serviceId;
          const serviceId = Array.isArray(queryServiceIds)
            ? queryServiceIds[0]
            : queryServiceIds;

          if (checkIsNonEmptyString(serviceId)) {
            sendBack({ data: { serviceId }, type: "SERVICE_ID_IN_URL" });
          } else {
            sendBack({ type: "SERVICE_ID_NOT_SPECIFIED" });
          }
        },
      ),
      checkIfRedirectToLoginNeeded: fromCallback(() => {
        checkAndRedirectToLoginOrHomepage(
          `${window.location.pathname}${window.location.search}`,
        );
      }),
      checkIfUserIsLoggedIn: fromCallback(({ sendBack }) => {
        const url = router.asPath;
        const searchParameters = new URLSearchParams(window.location.search);
        const offerId = searchParameters.get("offerId");
        const island = searchParameters.get("island");
        const isEsimFlow = url.toLowerCase().includes("/buy/esim");
        const isUserLoggedIn = user !== null;
        const hasOfferIdAndIsland = offerId !== null && island !== null;

        switch (true) {
          case isUserLoggedIn && isEsimFlow: {
            sendBack({
              data: { userId: user.idTokenClaims?.sub },
              returnUrl: url,
              type: "LOGGED_IN",
            });
            break;
          }

          case !isUserLoggedIn && isEsimFlow && hasOfferIdAndIsland: {
            void router.push({
              pathname: "/buy/esim",
              query: { island, offerId },
            });
            sendBack({ type: "LOGGED_OUT" });
            break;
          }

          case !isUserLoggedIn && !isEsimFlow: {
            sendBack({ type: "LOGGED_OUT" });
            break;
          }

          case isUserLoggedIn && !isEsimFlow: {
            const hasPreviouslyLoggedIn = localStorage.getItem(
              "hasPreviouslyLoggedIn",
            );

            if (hasPreviouslyLoggedIn === null) {
              localStorage.setItem("hasPreviouslyLoggedIn", "true");
            }

            sendBack({
              data: { userId: user.idTokenClaims?.sub },
              type: "LOGGED_IN",
            });
            break;
          }

          default: {
            sendBack({ type: "LOGGED_OUT" });
            break;
          }
        }
      }),
      checkServicesConfiguration: fromCallback(
        ({
          input,
          sendBack,
        }: {
          input: {
            accounts: Array<{ comverseAccountId: string; type: AccountType }>;
            selectedComverseAccountId: string;
          };
          sendBack: (event: AnyEventObject) => void;
        }) => {
          const { accounts, selectedComverseAccountId } = input;

          const postpaidAccountCount = accounts.filter(
            ({ type }) => type === AccountType.Postpaid,
          ).length;
          const prepaidAccountCount = accounts.filter(
            ({ type }) => type === AccountType.Prepaid,
          ).length;

          const selectedComverseAccountType = accounts.find(
            ({ comverseAccountId }) =>
              comverseAccountId === selectedComverseAccountId,
          )?.type;

          switch (true) {
            case selectedComverseAccountType === AccountType.Prepaid &&
              prepaidAccountCount === 1: {
              sendBack({ type: "SINGLE_PREPAID" });
              break;
            }

            case selectedComverseAccountType === AccountType.Prepaid &&
              prepaidAccountCount > 1: {
              sendBack({ type: "MULTIPLE_PREPAID" });
              break;
            }

            case selectedComverseAccountType === AccountType.Postpaid &&
              postpaidAccountCount === 1 &&
              prepaidAccountCount === 0: {
              sendBack({ type: "ONLY_POSTPAID" });
              break;
            }

            case selectedComverseAccountType === AccountType.Postpaid &&
              postpaidAccountCount > 0 &&
              prepaidAccountCount > 0: {
              sendBack({ type: "PRE_AND_POSTPAID" });
              break;
            }

            default: {
              sendBack({ type: "ONLY_POSTPAID" });
              break;
            }
          }
        },
      ),
      loadUserComverseAccounts: fromCallback(
        ({
          input,
          sendBack,
        }: {
          input: { userId: string };
          sendBack: (event: AnyEventObject) => void;
        }) => {
          const { userId } = input;

          void queryClient.invalidateQueries({
            queryKey: ["authAccounts", "authServices"],
          });

          void queryClient
            .fetchQuery({
              queryFn: async () => {
                return graphqlClient.request(accountsQuery);
              },
              queryKey: ["authAccounts", userId],
              staleTime: 0,
            })
            // eslint-disable-next-line promise/prefer-await-to-then
            .then((data) => {
              const { accounts } = data;

              const hasOnlyOneAccount = accounts.length === 1;
              const hasNoAccounts = accounts.length === 0;

              switch (true) {
                case hasNoAccounts: {
                  sendBack({ type: "USER_HAS_NO_ACCOUNTS_LINKED" });
                  break;
                }

                case hasOnlyOneAccount: {
                  sendBack({
                    data: { accounts },
                    type: "USER_HAS_ONLY_ONE_ACCOUNT_LINKED",
                  });
                  break;
                }

                default: {
                  sendBack({
                    data: { accounts },
                    type: "USER_HAS_ACCOUNTS_LINKED",
                  });
                  break;
                }
              }

              return data;
            });
        },
      ),
      loadUserServices: fromPromise(
        async ({
          input,
        }: {
          input: { selectedComverseAccountId: string; userId: string };
        }) => {
          const { selectedComverseAccountId, userId } = input;

          const data = await queryClient.fetchQuery({
            queryFn: async () => {
              return graphqlClient.request(servicesQuery, {
                comverseAccountId: selectedComverseAccountId,
                id: userId,
              });
            },
            queryKey: ["authServices", userId, selectedComverseAccountId],
          });

          return data.services;
        },
      ),
      redirectToAccountSelect: fromCallback(() => {
        const url = router.asPath;
        const currentPathname = router.pathname;
        const targetPathname = "/user/account-select";

        // Prevent redirection if already on the target page or if redirection has already occurred
        if (currentPathname === targetPathname) {
          return;
        }

        const isRedirectUrl = url.toLocaleLowerCase().includes("redirect=");
        const redirectUrl = isRedirectUrl ? url.split("?redirect=").at(-1) : "";

        if (checkIsNonEmptyString(redirectUrl)) {
          void router.replace({
            pathname: targetPathname,
            query: { redirectUrl },
          });
        } else {
          void router.replace(targetPathname);
        }
      }),
      redirectToEsimFlow: fromCallback(({ sendBack }) => {
        const url = new URL(window.location.href);
        const { pathname, search, searchParams } = url;
        const offerId = searchParams.get("offerId") ?? null;
        const island = searchParams.get("island") ?? null;
        const returnUrl = encodeURIComponent(pathname + search);

        if (offerId !== null && island !== null) {
          if (user) {
            sendBack({
              data: { island, offerId },
              type: "CONTINUE_WITH_ESIM_FLOW",
            });
          } else {
            sendBack({ returnUrl, type: "LOGGED_OUT" });
          }
        } else {
          void router.replace("/buy/esim/selectoffer");
        }
      }),
      redirectToLinkAccount: fromCallback(() => {
        void router.replace("/user/link-account/enter-number");
      }),
      removeAccountWithAccountId: fromPromise(
        async ({ input }: { input: { accountToRemove: string } }) => {
          const { accountToRemove } = input;

          void queryClient.invalidateQueries({
            queryKey: ["removeAccount"],
          });

          const data = await queryClient.fetchQuery({
            queryFn: async () => {
              return graphqlClient.request(removeAccountMutation, {
                comverseAccountId: accountToRemove,
              });
            },
            queryKey: ["removeAccount", accountToRemove],
          });

          if (data.deleteAccount.isSuccess) {
            void queryClient.invalidateQueries({
              queryKey: ["authAccounts", "authServices"],
            });

            void queryClient.refetchQueries({
              queryKey: ["authServices"],
            });

            return { accountToRemove, message: data.deleteAccount.message };
          } else {
            throw new Error(data.deleteAccount.message);
          }
        },
      ),
    },
  });

  return (
    <UserAccountMachineContext.Provider
      logic={manageUserAccountMachineImplementation}
      options={{
        inspect,
        state: userAccountMachineSnapshot,
      }}
    >
      {children}
    </UserAccountMachineContext.Provider>
  );
}

export {
  UserAccountMachineContext as default,
  UserAccountMachineLogicComponent,
};
