import { Button, Dialog, Paragraph } from "@jt/ui";
import Image from "next/image";
import { CookiesMachineContext } from "./machines/cookiesMachine/CookiesMachineContext";

interface CookieConsentProperties {
  onAccept: () => void;
  onReject: () => void;
}

function CookieConsent({ onAccept, onReject }: CookieConsentProperties) {
  const cookiesState = CookiesMachineContext.useSelector((state) => state);

  return (
    <Dialog
      cancelLabel="Reject All Cookies"
      isOpen={cookiesState.matches("ShowingCookieConsentPopup")}
      maxWidth="2xl"
      okLabel="Accept All Cookies"
      onCancelClick={() => {
        onReject();
      }}
      onClose={() => {
        onReject();
      }}
      onOKClick={() => {
        onAccept();
      }}
      title="Managing Cookies on My JT"
      titleClassName="flex-col md:flex-row text-lg md:text-xl gap-4"
      titleIcon={
        <Image
          alt="JT Logo"
          height="45"
          src="/images/JT_Logo@3x.png"
          width="89"
        />
      }
    >
      <>
        <Paragraph className="text-center md:text-left">
          To provide the best experiences, we use technologies like cookies to
          store and/or access device information. Consenting to these
          technologies will allow us to process data such as browsing behaviour
          or unique IDs on this site. Not consenting or withdrawing consent, may
          adversely affect certain features and functions.
        </Paragraph>
        <div className="flex items-center justify-center">
          <a
            href="https://www.jtglobal.com/global/cookie-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button link>Cookies Policy</Button>
          </a>
          <a
            href="https://www.jtglobal.com/global/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button link>Privacy Policy</Button>
          </a>
        </div>
      </>
    </Dialog>
  );
}

export default CookieConsent;
