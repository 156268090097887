import { Capacitor } from "@capacitor/core";
import type { App } from "@/config/configSchema";
import env from "@/config/environmentVariables";

function getAppConfig(): App {
  return {
    encodedReturnUrl: env.NEXT_PUBLIC_ENCODED_RETURN_URL,
    graphQLPath: env.NEXT_PUBLIC_GRAPHQL_PATH,
    isDevelopmentMode: env.NEXT_PUBLIC_NODE_ENV === "development",
    isNativeApp: Capacitor.isNativePlatform(),
    itemisationPageSize: env.NEXT_PUBLIC_ITEMISATION_PAGE_SIZE,
    logLevel: env.NEXT_PUBLIC_LOG_LEVEL,
    requestRetries: env.NEXT_PUBLIC_GRAPHQL_REQUEST_RETRIES,
    version: env.NEXT_PUBLIC_PACKAGE_VERSION,
  };
}

export default getAppConfig;
