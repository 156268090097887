import { Dialog } from "@jt/ui";
import { useState, useEffect, useRef } from "react";
import { removeProtocol } from "@/utils";

interface IframedChatDialogProperties {
  shouldShowChat?: boolean;
  onClose?: () => void;
}

/* eslint-disable react/iframe-missing-sandbox */
function IframedChatDialog({
  shouldShowChat = true,
  onClose,
}: IframedChatDialogProperties) {
  const [shouldShowDialog, setShouldShowDialog] =
    useState<boolean>(shouldShowChat);

  const iframeReference = useRef<HTMLIFrameElement>(null);

  const appHost = window.location.hostname;

  useEffect(() => {
    // Add event listener to catch messages from the iframe
    function handleMessage(event: MessageEvent) {
      if (
        iframeReference.current !== null &&
        event.source === iframeReference.current.contentWindow &&
        removeProtocol(event.origin) === appHost &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        event.data.chat.status === "closed"
      ) {
        handleCloseClick();
      }
    }

    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmountshow to use useRef in react
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  function handleCloseClick() {
    setShouldShowDialog(!shouldShowDialog);
    if (onClose) {
      onClose();
    }
  }

  return (
    <Dialog isOpen={shouldShowChat} onClose={handleCloseClick}>
      <iframe
        allowFullScreen
        className="h-[calc(100vh-170px)] max-h-[800px] w-full rounded-3xl"
        ref={iframeReference}
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://${appHost}/iframechat.html`}
        title="Jersey"
      />
    </Dialog>
  );
}

export default IframedChatDialog;
