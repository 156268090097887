import { Contacts } from "@capacitor-community/contacts";
import type { ContactPayload } from "@capacitor-community/contacts";

interface Contact {
  name?: string | null;
  phones?: Array<string>;
}

// eslint-disable-next-line function-name/starts-with-verb
async function retrieveListOfContacts(): Promise<Array<ContactPayload>> {
  const projection = {
    // Specify which fields should be retrieved.
    name: true,
    phones: true,
  };

  const { contacts } = await Contacts.getContacts({
    projection,
  });

  return contacts;
}

// eslint-disable-next-line function-name/starts-with-verb
async function simplifyListOfContacts(): Promise<Array<Contact>> {
  const contacts = await retrieveListOfContacts();

  if (contacts.length > 0) {
    return contacts.map((contact): Contact => {
      // no need to put contact name null into the list, because we want this value
      return {
        name: contact.name?.display,
        phones: contact.phones?.map((phone) =>
          phone.number === null ? "" : phone.number.replaceAll(/[\s()+-]/g, ""),
        ),
      };
    });
  }

  return [];
}

interface FindReplaceTargetByNameProperties {
  contacts: Array<Contact>;
  currentDisplay: string;
  targetNumber: string;
}

function findReplaceTargetByPhoneName({
  contacts,
  currentDisplay,
  targetNumber,
}: FindReplaceTargetByNameProperties): string {
  const foundContact = contacts.find((contact) => {
    return contact.phones?.some((phone) => phone === targetNumber);
  });

  return foundContact?.name === undefined || foundContact.name === null
    ? currentDisplay
    : currentDisplay.replaceAll(
        new RegExp(targetNumber, "g"),
        foundContact.name,
      );
}

export type { Contact };
export {
  simplifyListOfContacts,
  retrieveListOfContacts,
  findReplaceTargetByPhoneName,
};
