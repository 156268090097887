import { QueryClient } from "@tanstack/react-query";
import router from "next/router";
import config from "@/config";
import logger from "@/logger";

const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    retry(failureCount, error) {
      logger.trace(error);
      if (failureCount >= config.app.requestRetries) {
        void router.replace("/error");

        return false;
      }

      return true;
    },
    retryDelay: 10_000,
    staleTime: 1000 * 60 * 2,
  },
});

export default queryClient;
