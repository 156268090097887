import getAppConfig from "./appConfig";
import type { Sentry, SentryClient, SentryInit } from "@/config/configSchema";
import env from "@/config/environmentVariables";

function getSentryClientConfig(): SentryClient {
  return {
    enableNative: env.NEXT_PUBLIC_SENTRY_ENABLE_NATIVE,
    enableNativeNagger: false,
    replaysOnErrorSampleRate: env.NEXT_PUBLIC_SENTRY_ERROR_REPLAY_SAMPLE_RATE,
    replaysSessionSampleRate: env.NEXT_PUBLIC_SENTRY_SESSION_REPLAY_SAMPLE_RATE,
    tracePropagationTargets: [getAppConfig().graphQLPath],
  } as unknown as SentryClient;
}

const sentryClientConfig = getSentryClientConfig();

function getSentryInitConfig(): SentryInit {
  return {
    debug: env.NEXT_PUBLIC_SENTRY_DEBUG,
    dsn: env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: env.NEXT_PUBLIC_SENTRY_ENABLED,
    environment: env.NEXT_PUBLIC_SENTRY_ENV,
    release: `my-jt-app@${env.NEXT_PUBLIC_PACKAGE_VERSION}`,
    sampleRate: env.NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE,
    tracesSampleRate: env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
  } as unknown as SentryInit;
}

const sentryInitConfig = getSentryInitConfig() as unknown as SentryInit;

function getSentryConfig(): Sentry {
  return {
    client: sentryClientConfig,
    init: sentryInitConfig,
  };
}

export default getSentryConfig;
