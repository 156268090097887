import type {
  BroadbandService,
  LandlineService,
  PostPaidMobileService,
  PrePaidMobileService,
  Service,
} from "./types";
import { AccountType, ServiceType } from "@/__generated__/graphql";
import type {
  ServiceResult,
  PrePaidMobileService as SerivcesPrePaidMobile,
} from "@/components/contexts/servicesTypes";
import type { Service as selectedService } from "@/components/machines/userAccountMachine/types";

function isPostpaidService(
  service: SerivcesPrePaidMobile | ServiceResult,
): service is ServiceResult {
  return service.accountType === AccountType.Postpaid;
}

function isPrepaidService(
  service: SerivcesPrePaidMobile | ServiceResult,
): service is SerivcesPrePaidMobile {
  return service.accountType === AccountType.Prepaid;
}

function isPrePaidMobileService(
  service: Service,
): service is PrePaidMobileService {
  return service.__typename === "PrePaidMobileService";
}

function isServiceInstanceOfLandline(
  service: Service,
): service is LandlineService {
  return service.serviceType === ServiceType.Landline;
}

function isServiceInstanceOfBroadband(
  service: Service,
): service is BroadbandService {
  return service.serviceType === ServiceType.Broadband;
}

function isServiceInstanceOfMobile(
  service: Service,
): service is PostPaidMobileService {
  return service.serviceType === ServiceType.Mobile;
}

function isServiceInstanceOfPostpaidMobile(
  service: Service,
): service is PostPaidMobileService {
  return service.__typename === "PostPaidMobileService";
}

function isSelectedServicePrepaid(service: selectedService): boolean {
  return service.__typename === "PrePaidMobileService";
}

function isSelectedServicePostpaidMobile(service: selectedService): boolean {
  return service.__typename === "PostPaidMobileService";
}

function isSelectedServiceLandline(service: selectedService): boolean {
  return service.__typename === "LandlineService";
}

function isSelectedServiceBroadband(service: selectedService): boolean {
  return service.__typename === "BroadbandService";
}

export {
  isPostpaidService,
  isPrepaidService,
  isPrePaidMobileService,
  isServiceInstanceOfLandline,
  isServiceInstanceOfBroadband,
  isServiceInstanceOfMobile,
  isServiceInstanceOfPostpaidMobile,
  isSelectedServicePrepaid,
  isSelectedServiceBroadband,
  isSelectedServiceLandline,
  isSelectedServicePostpaidMobile,
};
