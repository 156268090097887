/* eslint-disable canonical/sort-keys */
import { z } from "zod";

const appSchema = z.object({
  encodedReturnUrl: z.string(),
  graphQLPath: z.string(),
  isDevelopmentMode: z.boolean(),
  isNativeApp: z.boolean(),
  itemisationPageSize: z.coerce.number(),
  logLevel: z.string(),
  requestRetries: z.coerce.number(),
  version: z.string(),
});

const azureAuthProviderSchema = z.object({
  clientId: z.string(),
  domain: z.string(),
  signUpPath: z.string(),
  signUpPolicyId: z.string(),
  signUpSignInPath: z.string(),
  signUpSignInPolicyId: z.string(),
  tenantDomain: z.string(),
  nativeRedirectUri: z.string(),
});

const otherThirdPartiesSchema = z.object({
  gaMeasurementId: z.string(),
  olarkSiteId: z.string(),
});

const sentryClientSchema = z.object({
  enableNative: z.boolean(),
  enableNativeNagger: z.boolean(),
  replaysOnErrorSampleRate: z.coerce.number(),
  replaysSessionSampleRate: z.coerce.number(),
  tracePropagationTargets: z.array(z.string()),
});

const sentryInitSchema = z.object({
  debug: z.boolean(),
  dsn: z.string(),
  enabled: z.boolean(),
  environment: z.string(),
  release: z.string(),
  sampleRate: z.coerce.number(),
  tracesSampleRate: z.coerce.number(),
});

const sentrySchema = z.object({
  client: sentryClientSchema,
  init: sentryInitSchema,
});

const configSchema = z.object({
  app: appSchema,
  azureAuthProvider: azureAuthProviderSchema,
  otherThirdParties: otherThirdPartiesSchema,
  sentry: sentrySchema,
});

export const schemaDefinitions = {
  app: appSchema,
  azureAuthProvider: azureAuthProviderSchema,
  otherThirdParties: otherThirdPartiesSchema,
  sentry: sentrySchema,
  sentryClient: sentryClientSchema,
  sentryInit: sentryInitSchema,
};

export const environmentVariablesClientConfig = {
  NEXT_PUBLIC_ENCODED_RETURN_URL: z.string(),
  NEXT_PUBLIC_GRAPHQL_PATH: z.string(),
  NEXT_PUBLIC_GRAPHQL_REQUEST_RETRIES: z.coerce.number(),
  NEXT_PUBLIC_ITEMISATION_PAGE_SIZE: z.coerce.number(),
  NEXT_PUBLIC_LOG_LEVEL: z.string(),
  NEXT_PUBLIC_NODE_ENV: z.string(),
  NEXT_PUBLIC_PACKAGE_VERSION: z.string(),
  NEXT_PUBLIC_SENTRY_ENABLE_NATIVE: z.coerce.boolean(),
  NEXT_PUBLIC_SENTRY_ERROR_REPLAY_SAMPLE_RATE: z.coerce.number(),
  NEXT_PUBLIC_SENTRY_SESSION_REPLAY_SAMPLE_RATE: z.coerce.number(),
  NEXT_PUBLIC_SENTRY_DEBUG: z.coerce.boolean(),
  NEXT_PUBLIC_SENTRY_DSN: z.string(),
  NEXT_PUBLIC_SENTRY_ENABLED: z.coerce.boolean(),
  NEXT_PUBLIC_SENTRY_ENV: z.string(),
  NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE: z.coerce.number(),
  NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: z.coerce.number(),
  NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB: z.string(),
  NEXT_PUBLIC_GA_MEASUREMENT_ID_ANDROID: z.string(),
  NEXT_PUBLIC_GA_MEASUREMENT_ID_IOS: z.string(),
  NEXT_PUBLIC_OLARK_ID: z.string(),
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_DOMAIN: z.string(),
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_TENANT_DOMAIN: z.string(),
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_POLICY_ID: z.string(),
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_SIGN_IN_POLICY_ID: z.string(),
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_CLIENT_ID: z.string(),
  NEXT_PUBLIC_AZURE_AUTH_ANDROID_REDIRECT_URI: z.string(),
  NEXT_PUBLIC_AZURE_AUTH_IOS_REDIRECT_URI: z.string(),
};

export const environmentVariablesClientConfigZodObject = z.object(
  environmentVariablesClientConfig,
);
export type EnvironmentVariablesClientConfig = z.infer<
  typeof environmentVariablesClientConfigZodObject
>;

export type App = z.infer<typeof appSchema>;
export type AzureAuthProvider = z.infer<typeof azureAuthProviderSchema>;
export type OtherThirdParties = z.infer<typeof otherThirdPartiesSchema>;
export type Sentry = z.infer<typeof sentrySchema>;
export type SentryClient = z.infer<typeof sentryClientSchema>;
export type SentryInit = z.infer<typeof sentryInitSchema>;

export type Config = z.infer<typeof configSchema>;
