import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { useRouter } from "next/router";
import { CookiesMachineContext } from "./machines/cookiesMachine/CookiesMachineContext";
import CookieConsent from "@/components/CookieConsent";
import config from "@/config";
import logger from "@/logger";

function checkCookieEntry(hasAcceptedCookies: string | null): boolean | null {
  switch (hasAcceptedCookies) {
    case "true": {
      return true;
    }

    case "false": {
      return false;
    }

    default: {
      return null;
    }
  }
}

/**
 *
 */
async function enable(): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  await FirebaseAnalytics.setEnabled({
    enabled: true,
  });
}

/**
 *
 * @param name
 * @param nameOverride
 */
async function setScreenName(
  name: string,
  nameOverride: string,
): Promise<void> {
  await FirebaseAnalytics.setCurrentScreen({
    screenClassOverride: nameOverride,
    screenName: name,
  });
}

function sendAnalyticsEvent(
  action: string,
  category: string,
  label: string,
): void {
  ReactGA.event({
    action,
    category,
    label,
  });
}

function handleRouteChange(url: string) {
  try {
    // when page URL changed, document title not yet changed until next render
    // so we have to delay sending a pageview hit to Google Analytics
    setTimeout(() => {
      void setScreenName(document.title, url);
    }, 1000);
  } catch (error) {
    logger.error((error as Error).message);
  }
}

function AppAnalytics() {
  const router = useRouter();

  const cookiesActorReference = CookiesMachineContext.useActorRef();

  const [hasAcceptedCookies, setHasAcceptedCookies] = useState<boolean | null>(
    null,
  );
  const [isIframed, setIsIframed] = useState(false);

  useEffect(() => {
    if (
      Capacitor.getPlatform() === "ios" &&
      checkCookieEntry(localStorage.getItem("hasAcceptedCookies")) === true
    ) {
      router.events.on("routeChangeComplete", handleRouteChange);
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    setHasAcceptedCookies(
      checkCookieEntry(localStorage.getItem("hasAcceptedCookies")),
    );

    // Check if the page is being iframed
    setIsIframed(window.self !== window.top);

    if (config.app.isNativeApp || hasAcceptedCookies === true) {
      const measurementId = config.otherThirdParties.gaMeasurementId;

      if (Capacitor.getPlatform() === "ios") {
        try {
          void enable();
          void setScreenName(document.title, document.URL);
        } catch (error) {
          logger.error((error as Error).message);
        }
      } else {
        ReactGA.initialize(measurementId);
        ReactGA.send({
          hitType: "pageview",
          page: document.URL,
          title: document.title,
        });
      }
    }
  }, [hasAcceptedCookies]);

  // if it is iframed, e.g. after payment, don't show the cookie consent
  if (isIframed) {
    return null;
  }

  return (
    <CookieConsent
      onAccept={() => {
        cookiesActorReference.send({
          consent: "Accepted",
          type: "UPDATE_CONSENT",
        });
      }}
      onReject={() => {
        cookiesActorReference.send({
          consent: "Denied",
          type: "UPDATE_CONSENT",
        });
      }}
    />
  );
}

export { checkCookieEntry, enable, sendAnalyticsEvent, setScreenName };
export default AppAnalytics;
