/* eslint-disable no-console */
import type { Config } from "@/config/configSchema";
import getAppConfig from "@/config/appConfig";
import getAzureAuthProviderConfig from "@/config/azureAuthProviderConfig";
import getOtherThirdPartiesConfig from "@/config/otherThirdPartiesConfig";
import getSentryConfig from "@/config/sentryConfig";

const appConfig = getAppConfig();

const azureAuthProviderConfig = getAzureAuthProviderConfig();

const otherThirdPartiesConfig = getOtherThirdPartiesConfig();

const sentryConfig = getSentryConfig();

// unknown as Config assertion is used to enable seamless variable injections at docker entrypoint
const config: Config = {
  app: appConfig,
  azureAuthProvider: azureAuthProviderConfig,
  otherThirdParties: otherThirdPartiesConfig,
  sentry: sentryConfig,
} as unknown as Config;

// Show console.log only on the server side
if (typeof window === "undefined") {
  console.log(JSON.stringify(config, null, 2));
}

export default config;
