/* eslint-disable unicorn/filename-case */
/* eslint-disable func-style -- requestMiddleware, responseMiddleware have to use arrow functions, because needed type definition from graphql-request do not exported */
/* eslint-disable function-name/starts-with-verb -- requestMiddleware, responseMiddleware have to match with graphql request config name */
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo, useRef } from "react";
import type { RequestMiddleware, ResponseMiddleware } from "graphql-request";
import { GraphQLClient } from "graphql-request";
import type { Client } from "graphql-sse";
import { createClient } from "graphql-sse";
import { Capacitor } from "@capacitor/core";
import logger from "@/logger";
import config from "@/config";
import { loginRequest } from "@/azureAuthConfig";

const GraphQLContext = createContext<{
  closeSubscriptions: () => void;
  graphqlClient: GraphQLClient;
  graphqlSubscriptionsClient: Client;
  reinitializeSubscriptionsClient: () => void;
} | null>(null);

interface GraphQLProviderProperties {
  children: ReactNode;
}
interface CustomResponseError extends Error {
  response: {
    errors: Array<{
      code: string;
    }>;
  };
}

function isErrorWithResponse(error: Error): error is CustomResponseError {
  return error.hasOwnProperty("response");
}

export function GraphQLProvider({ children }: GraphQLProviderProperties) {
  const { instance, accounts, inProgress } = useMsal();
  const router = useRouter();
  const tokenReference = useRef<string | null>(null);
  const subscriptionsClient = useRef<Client | null>(null);

  const requestMiddlewareAddFreshToken: RequestMiddleware = async (request) => {
    const token = await getAccessTokenAndUpdateItsReference();

    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  };

  const responseMiddlewareHandleErrors: ResponseMiddleware = (response) => {
    if (
      response instanceof Error &&
      isErrorWithResponse(response) &&
      response.response.errors.length > 0
    ) {
      switch (response.response.errors[0].code) {
        case "UNEXPECTED_ERROR": {
          throw new Error(response.response.errors[0].code);
        }

        case "FORBIDDEN": {
          void router.replace("/");
          break;
        }

        case "UNAUTHORIZED": {
          void router.replace("/");
          break;
        }

        case "EXPIRED": {
          logger.info(
            "Token expired, it shouldn't happen, it should have been refreshed",
          );
          void instance.acquireTokenRedirect({
            ...loginRequest,
            account: accounts[0],
          });
          break;
        }

        default: {
          break;
        }
      }
    }
  };

  const graphqlRequestClient = new GraphQLClient(config.app.graphQLPath, {
    requestMiddleware: requestMiddlewareAddFreshToken,
    responseMiddleware: responseMiddlewareHandleErrors,
  });

  function initializeSubscriptionsClient() {
    return createClient({
      headers: () => {
        return {
          Authorization: `Bearer ${tokenReference.current}`,
        };
      },
      url: config.app.graphQLPath,
    });
  }

  subscriptionsClient.current = initializeSubscriptionsClient();

  /**
   * It silently gets the access token and updates the reference and updated the token reference
   * which is needed by the graphql-sse client.
   * ⚠️ In the future this function will need updating to handle unauthenticated users
   * @returns {Promise<string>} Access token
   */
  async function getAccessTokenAndUpdateItsReference(): Promise<string> {
    const defaultAccount = accounts[0] ?? undefined;

    logger.trace({ defaultAccount, inProgress }, "getAccessToken");

    const tokenRequest = {
      ...loginRequest,
      account: defaultAccount,
    };

    // Internet says that MSAL is very silly and doing something that checks authentication before acquiring a token can help things???
    instance.getActiveAccount();

    try {
      const response = await instance.acquireTokenSilent(tokenRequest);

      logger.trace({ response }, "👉 getAccessToken");

      tokenReference.current = response.accessToken;

      return response.accessToken;
    } catch (error) {
      window.localStorage.removeItem("selectedComverseAccountId");

      // Catch interaction_required errors and call interactive method to resolve
      // eslint-disable-next-line unicorn/prefer-ternary
      if (
        error instanceof InteractionRequiredAuthError &&
        Capacitor.getPlatform() !== "android"
      ) {
        await instance.acquireTokenRedirect(tokenRequest);
      } else {
        // If anything else goes wrong, just sign out, i have no idea what else we can do.
        // This will likely be a result of refresh tokens being expired, which by design msal does not let us look at 🥲
        await instance.logoutRedirect({
          postLogoutRedirectUri: config.app.isNativeApp
            ? config.azureAuthProvider.nativeRedirectUri
            : "/",
        });

        // I don't trust anything Microsoft does anymore.
      }

      throw error;
    }
  }

  function closeSubscriptions() {
    if (subscriptionsClient.current) {
      subscriptionsClient.current.dispose();
    }
  }

  function reinitializeSubscriptionsClient() {
    closeSubscriptions();
    subscriptionsClient.current = initializeSubscriptionsClient();
  }

  const values = useMemo(() => {
    return {
      closeSubscriptions,
      graphqlClient: graphqlRequestClient,
      graphqlSubscriptionsClient:
        subscriptionsClient.current ?? initializeSubscriptionsClient(),
      reinitializeSubscriptionsClient,
    };
  }, []);

  return (
    <GraphQLContext.Provider value={values}>{children}</GraphQLContext.Provider>
  );
}

export function useGraphQLContext() {
  const context = useContext(GraphQLContext);

  if (context === null) {
    throw new Error("useGraphQLContext must be used within a GraphQLProvider");
  }

  return context;
}

export { GraphQLContext };
