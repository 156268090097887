import { Button } from "@jt/ui";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@/azureAuthConfig";
import config from "@/config";

interface LoginButtonProperties {
  className?: string;
  color?: "primary" | "secondary" | "tertiary";
  size?: "extrasmall" | "medium";
  "data-cy"?: string;
  label?: string;
  isLink?: boolean;
  isSignUp?: boolean;
}

function LoginButton({
  className = "",
  color = "primary",
  size = "medium",
  label = "Log-in",
  "data-cy": dataCy = "button-login",
  isLink = false,
  isSignUp = false,
}: LoginButtonProperties) {
  const { instance } = useMsal();

  function handleLogin() {
    // If a user closes the @Capacitor/Browser window, or you close the tab in the browser then this key will still exist and cause interaction_in_progress error.
    window.localStorage.removeItem("msal.interaction.status");

    if (isSignUp) {
      void instance.loginRedirect({
        ...loginRequest,
        authority: config.azureAuthProvider.signUpPath,
      });
    } else {
      void instance.loginRedirect(loginRequest);
    }
  }

  return isLink ? (
    <button
      className={className}
      data-cy={dataCy}
      onClick={handleLogin}
      type="button"
    >
      Log-in
    </button>
  ) : (
    <Button
      aria-label={label}
      className={className}
      color={color}
      data-cy={dataCy}
      onClick={handleLogin}
      size={size}
    >
      {label}
    </Button>
  );
}

export default LoginButton;
