// eslint-disable-next-line canonical/prefer-import-alias -- cypress also need import this file, which cannot support alias import`
import config from "./config";

const postLogoutRedirectUri = config.app.isNativeApp ? "/logout" : "/";

const redirectUri = config.app.isNativeApp
  ? config.azureAuthProvider.nativeRedirectUri
  : undefined;

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  redirectUri,
  scopes: ["openid", "offline_access", config.azureAuthProvider.clientId],
};

export const b2cPolicies = {
  changeEmail: {
    authority: `https://${config.azureAuthProvider.domain}/${config.azureAuthProvider.tenantDomain}/B2C_1A_DEMO_CHANGESIGNINNAME`,
    redirectUri,
    scopes: [],
  },
  changePassword: {
    authority: `https://${config.azureAuthProvider.domain}/${config.azureAuthProvider.tenantDomain}/B2C_1A_PASSWORDCHANGE`,
    redirectUri,
    scopes: [],
  },
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    authority: config.azureAuthProvider.signUpSignInPath,
    clientId: config.azureAuthProvider.clientId,
    knownAuthorities: [config.azureAuthProvider.domain],
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    secureCookies: false,
    storeAuthStateInCookie: false,
    temporaryCacheLocation: "localStorage",
  },
  system: {
    // Disables Web Account Manager (WAM) on Windows: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/device-bound-tokens.md
    allowNativeBroker: false,
  },
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
