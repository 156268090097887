import { BackgroundGradient } from "@jt/ui";
import MenuItem from "@/components/layouts/MenuItem";

interface MobileNavProperties {
  className?: string;
  onClose?: () => void;
}

function MobileNav({ className = "", onClose }: MobileNavProperties) {
  return (
    <div
      className={`fixed left-0 top-0 z-50 h-screen w-full md:hidden ${className}`}
    >
      <BackgroundGradient full />
      <div className="absolute inset-y-28 w-full text-white">
        <nav className="absolute bottom-16 top-0 w-full overflow-y-auto">
          <MenuItem linkClassName="block w-full p-4 pl-6" onClose={onClose} />
        </nav>
      </div>
    </div>
  );
}
export default MobileNav;
