import type { JSX } from "react";
import * as Sentry from "@sentry/nextjs";
import Template, { TemplateProvider } from "./layouts/Template";
import { UserAccountMachineLogicComponent } from "./machines/userAccountMachine/UserAccountMachineContext";
import { TopupMachineContextInjector } from "@/components/machines/topupMachine/TopupMachineContext";
import useAuth from "@/useAuth";

function AuthenticatedMyJtApp({ children }: { children: JSX.Element }) {
  const { user } = useAuth();

  Sentry.setUser({
    // No email to avoid tracking personal data
    // email: user?.username ?? "UNAUTHENTICATED_USER",
    id: user?.localAccountId ?? "UNAUTHENTICATED_USER",
  });

  return (
    <UserAccountMachineLogicComponent>
      <TopupMachineContextInjector>
        <TemplateProvider>
          <Template>{children}</Template>
        </TemplateProvider>
      </TopupMachineContextInjector>
    </UserAccountMachineLogicComponent>
  );
}

export default AuthenticatedMyJtApp;
