import { createBrowserInspector } from "@statelyai/inspect";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { ReactNode } from "react";
import type { InspectionEvent, Observer } from "xstate";

const MachineUtilContext = createContext<{
  inspect?: Observer<InspectionEvent>;
} | null>(null);

export function MachineUtilProvider({ children }: { children: ReactNode }) {
  const [inspect, setInspect] = useState<
    Observer<InspectionEvent> | undefined
  >();

  useEffect(() => {
    setInspect(
      localStorage.getItem("xstate-inspector-enabled") === null
        ? undefined
        : createBrowserInspector().inspect,
    );
  }, []);

  const values = useMemo(() => {
    return { inspect };
  }, [inspect]);

  return (
    <MachineUtilContext.Provider value={values}>
      {children}
    </MachineUtilContext.Provider>
  );
}

export function useMachineUtil() {
  const context = useContext(MachineUtilContext);

  if (context === null) {
    throw new Error(`Machine Util context must be used within the provider`);
  }

  return context;
}

export { MachineUtilContext };
