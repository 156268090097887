/* for App version, handle ios notch */
import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { SafeArea } from "capacitor-plugin-safe-area";
import config from "@/config";

async function setNotch() {
  const isDebug = config.app.isNativeApp && Capacitor.getPlatform() === "web";
  let { insets } = await SafeArea.getSafeAreaInsets();

  if (isDebug) {
    insets = { bottom: 22, left: 0, right: 0, top: 44 };
  }

  if (config.app.isNativeApp && Capacitor.getPlatform() === "ios") {
    document.documentElement.style.setProperty(
      "--notchHeadHeight",
      `${insets.top}px`,
    );
    document.documentElement.style.setProperty(
      "--notchFootHeight",
      `${insets.bottom}px`,
    );
  }
}

function useIosNotch(): void {
  useEffect(() => {
    void setNotch();
  }, []);
}
export default useIosNotch;
