import { CircledInfo } from "@jt/ui";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { ReactNode } from "react";
import { v4 } from "uuid";

const ToastContext = createContext<{
  showToast: (props: {
    backgroundColour?: string;
    message: string;
    icon?: ReactNode;
    textColour?: string;
  }) => void;
} | null>(null);

interface Toast {
  backgroundColour: string;
  message: string;
  icon: ReactNode;
  id: string;
  textColour: string;
  timeRemaining: number;
}

export function ToastProvider({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<Array<Toast>>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setToasts((previousToasts) =>
        previousToasts
          .filter((toast) => toast.timeRemaining > 0)
          .map((item) => {
            return {
              ...item,
              timeRemaining: item.timeRemaining - 0.2,
            };
          }),
      );
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function showToast({
    backgroundColour = "destructive",
    icon = <CircledInfo />,
    message,
    textColour = "white",
  }: {
    icon?: ReactNode;
    message: string;
    backgroundColour?: string;
    textColour?: string;
  }) {
    setToasts((previous) => [
      {
        backgroundColour,
        icon,
        id: v4(),
        message,
        textColour,
        timeRemaining: 5,
      },
      ...previous,
    ]);
  }

  const values = useMemo(() => {
    return { showToast };
  }, []);

  return (
    <ToastContext.Provider value={values}>
      {children}
      {toasts.length > 0 ? (
        <div className="pointer-events-none fixed bottom-10 left-1/2 z-[100] w-full -translate-x-1/2 px-4">
          <div
            className={`flex flex-col-reverse items-center gap-2 transition-all duration-200 ${toasts[0].timeRemaining === 5 ? "animate-toast-slide-up" : ""}`}
          >
            {toasts.map((toast, index) => (
              <div
                className={`flex min-h-10 animate-toast-slide-up flex-row items-center gap-2 rounded p-4 transition-all duration-200 bg-${toast.backgroundColour} text-${toast.textColour} z-${index}`}
                key={toast.id}
                style={{ opacity: toast.timeRemaining > 0.2 ? 1 : 0 }}
              >
                <div>{toast.icon}</div>
                <div>{toast.message}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = useContext(ToastContext);

  if (context === null) {
    throw new Error(`Toast context must be used within the provider`);
  }

  return context;
}

export { ToastContext };
