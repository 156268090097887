import UserAccountMachineContext from "@/components/machines/userAccountMachine/UserAccountMachineContext";
import { isPrePaidMobileService } from "@/components/machines/userAccountMachine/typeGuards";

function useSelectedServiceType(): ServiceType {
  const state = UserAccountMachineContext.useSelector((s) => s);

  const { selectedService } = state.context;

  if (!selectedService) {
    return "Postpaid";
  }

  return isPrePaidMobileService(selectedService) ? "Prepaid" : "Postpaid";
}

export type ServiceType = "Postpaid" | "Prepaid";

export default useSelectedServiceType;
