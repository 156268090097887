import { type ReactNode } from "react";
import { fromCallback } from "xstate";
import { useMsal } from "@azure/msal-react";
import { createActorContext } from "@xstate/react";
import type { CookiesMachineEvents } from "./cookiesMachine";
import cookiesMachine from "./cookiesMachine";
import { loginRequest } from "@/azureAuthConfig";
import useAuth from "@/useAuth";
import { useMachineUtil } from "@/components/contexts/MachineUtil";
import config from "@/config";

const CookiesMachineContext = createActorContext(cookiesMachine);

function CookiesProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const { instance, inProgress } = useMsal();
  const { inspect } = useMachineUtil();

  if (inProgress === "startup" || inProgress === "handleRedirect") return null;

  const manageCookiesMachineImplementation = cookiesMachine.provide({
    actions: {
      redirectToLoginScreen: () => {
        if (!config.app.isNativeApp) {
          void instance.loginRedirect(loginRequest);
        }
      },
    },
    actors: {
      checkIfUserHasLoggedInBefore: fromCallback(
        ({ sendBack }: { sendBack: (event: CookiesMachineEvents) => void }) => {
          const hasSignedInPreviously = localStorage.getItem(
            "hasPreviouslyLoggedIn",
          );

          const isEsimPurchaseFlow =
            window.location.pathname.includes("/buy/esim");

          switch (true) {
            case isEsimPurchaseFlow && user === null: {
              sendBack({ type: "ESIM_FLOW" });
              break;
            }

            case user !== null: {
              sendBack({ type: "USER_IS_SIGNED_IN" });
              break;
            }

            case hasSignedInPreviously === "true": {
              sendBack({ type: "USER_HAS_PREVIOUSLY_LOGGED_IN" });
              break;
            }

            default: {
              sendBack({ type: "NEW_USER" });
            }
          }
        },
      ),
    },
  });

  return (
    <CookiesMachineContext.Provider
      logic={manageCookiesMachineImplementation}
      options={{
        inspect,
      }}
    >
      {children}
    </CookiesMachineContext.Provider>
  );
}

export { CookiesMachineContext, CookiesProvider };
