/* eslint-disable canonical/sort-keys */
import type { EnvironmentVariablesClientConfig } from "@/config/configSchema";

const defaultEnvironmentVariables: EnvironmentVariablesClientConfig = {
  NEXT_PUBLIC_ENCODED_RETURN_URL: "https://my-staging.jtglobal.com/success",
  NEXT_PUBLIC_GRAPHQL_PATH: "http://localhost:4000/api/graphql",
  NEXT_PUBLIC_GRAPHQL_REQUEST_RETRIES: 3,
  NEXT_PUBLIC_ITEMISATION_PAGE_SIZE: 10,
  NEXT_PUBLIC_LOG_LEVEL: "trace",
  NEXT_PUBLIC_NODE_ENV: "production",
  NEXT_PUBLIC_PACKAGE_VERSION: "develop 🔨",
  NEXT_PUBLIC_SENTRY_ENABLE_NATIVE: true,
  NEXT_PUBLIC_SENTRY_ERROR_REPLAY_SAMPLE_RATE: 1,
  NEXT_PUBLIC_SENTRY_SESSION_REPLAY_SAMPLE_RATE: 0.1,
  NEXT_PUBLIC_SENTRY_DEBUG: false,
  NEXT_PUBLIC_SENTRY_DSN: "",
  NEXT_PUBLIC_SENTRY_ENABLED: true,
  NEXT_PUBLIC_SENTRY_ENV: "local-development",
  NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE: 0.1,
  NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: 0.1,
  NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB: "G-XXXXX",
  NEXT_PUBLIC_GA_MEASUREMENT_ID_ANDROID: "G-YYYYY",
  NEXT_PUBLIC_GA_MEASUREMENT_ID_IOS: "G-ZZZZZ",
  NEXT_PUBLIC_OLARK_ID: "",
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_DOMAIN: "login-dev.jtglobal.com",
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_TENANT_DOMAIN: "jtlogindev01.onmicrosoft.com",
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_POLICY_ID: "B2C_1_SIGN_UP",
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_SIGN_IN_POLICY_ID:
    "B2C_1_SIGN_UP_SIGN_IN",
  NEXT_PUBLIC_AZURE_AUTH_PROVIDER_CLIENT_ID:
    "5f91514b-3bd1-4f75-a009-6386160a337c",
  NEXT_PUBLIC_AZURE_AUTH_ANDROID_REDIRECT_URI:
    "msauth://com.jtglobal.my.stage.app/2jmj7l5rSw0yVb%2FvlWAYkK%2FYBwk%3D",
  NEXT_PUBLIC_AZURE_AUTH_IOS_REDIRECT_URI:
    "msauth.com.jtglobal.my.stage.app://auth",
};

export default defaultEnvironmentVariables;
