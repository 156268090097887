/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from "react";
import Script from "next/script";
import config from "@/config";
import logger from "@/logger";

declare global {
  interface Window {
    olark: any;
  }
}

function Chat() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [shouldShowMobileButton, setShouldShowMobileButton] = useState(false);

  useEffect(() => {
    if (config.app.isNativeApp && typeof window.olark === "function") {
      window.olark("api.box.hide");
    } else if (typeof window.olark === "function") {
      window.olark("api.box.show");
    }
  }, []);

  function checkOlarkReady() {
    if (document.querySelectorAll(".olark-launch-button").length > 0) {
      setShouldShowMobileButton(true);

      return;
    }

    setTimeout(checkOlarkReady, 100);
  }

  useEffect(() => {
    if (typeof window.olark === "function") {
      window.olark("api.chat.onOperatorsAvailable", () => {
        logger.info("agents available");
      });
      window.olark("api.chat.onOperatorsAway", () => {
        logger.info("agents unavailable");
      });
      window.olark("api.box.onShrink", () => {
        if (config.app.isNativeApp) {
          window.olark("api.box.hide");
        }
        setIsChatOpen(false);
      });
      if (isChatOpen) {
        const olarkElements = document.querySelectorAll(".olark-launch-button");

        olarkElements.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.click();
          }
        });
      }
    }
    checkOlarkReady();
  }, [isChatOpen]);

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `;(function (o, l, a, r, k, y) {
              r = "script";
              y = l.createElement(r);
              r = l.getElementsByTagName(r)[0];
              y.async = 1;
              y.src = "https://" + a;
              r.parentNode.insertBefore(y, r);
              y = o.olark = function () {
                  k.s.push(arguments);
                  k.t.push(+new Date)
              };
              y.extend = function (i, j) {
                  y("extend", i, j)
              };
              y.identify = function (i) {
                  y("identify", k.i = i)
              };
              y.configure = function (i, j) {
                  y("configure", i, j);
                  k.c[i] = j
              };
              k = y._ = {s: [], t: [+new Date], c: {}, l: a};
          })(window, document, "static.olark.com/jsclient/loader.js");
          olark.identify('${config.otherThirdParties.olarkSiteId}');
          olark.configure('box.start_hidden', true);
          olark.configure('box.inline', false);
          olark.configure('system.hb_show_button_text', false);
          olark.configure('system.hb_chatbox_size', 'md');
          olark.configure('system.hb_hide_minimize_button', false);
          olark.configure('features.google_analytics', true );
          olark.configure('system.google_analytics_id', '${config.otherThirdParties.gaMeasurementId}');
          olark("api.chat.onReady", () => {
                window.olark("api.box.hide");
        });`,
        }}
        id="show-chat"
      />

      {shouldShowMobileButton && !isChatOpen ? (
        <div
          aria-hidden="true"
          className="fixed right-0 top-2/3 z-50 -mt-28 block origin-bottom-right -rotate-90 cursor-pointer rounded-t-lg bg-primary px-4 py-1 text-xs font-bold text-white sm:px-8 sm:text-lg md:top-1/2 md:hidden"
          onClick={() => {
            setIsChatOpen(!isChatOpen);
          }}
        >
          Live Chat
        </div>
      ) : null}
    </>
  );
}

export default Chat;
