import { useMsal } from "@azure/msal-react";
import { Button } from "@jt/ui";
import config from "@/config";

interface LogoutButtonProperties {
  className?: string;
  color?: "primary" | "secondary" | "white";
  isOutlined?: boolean;
  isLink?: boolean;
  size?: "extrasmall" | "medium";
  label?: string;
}

function LogoutButton({
  className = "",
  color = "white",
  isOutlined = true,
  isLink = false,
  size = "extrasmall",
  label = "Sign Out",
}: LogoutButtonProperties) {
  const { instance } = useMsal();

  function handleLogout() {
    // If a user closes the @Capacitor/Browser window, or you close the tab in the browser then this key will still exist and cause interaction_in_progress error.
    window.localStorage.removeItem("msal.interaction.status");

    const logoutRequest = {
      account: instance.getActiveAccount(),
      idTokenHint: instance.getActiveAccount()?.idToken,
      postLogoutRedirectUri: config.app.isNativeApp
        ? config.azureAuthProvider.nativeRedirectUri
        : "/",
    };

    void instance.logoutRedirect(logoutRequest);
  }

  return isLink ? (
    <div
      aria-hidden="true"
      aria-label="Sign Out"
      className={className}
      data-cy="button-logout"
      onClick={handleLogout}
    >
      {label}
    </div>
  ) : (
    <Button
      aria-hidden="true"
      aria-label="Sign Out"
      className={className}
      color={color}
      data-cy="button-logout"
      onClick={handleLogout}
      outlined={isOutlined}
      size={size}
    >
      {label}
    </Button>
  );
}

export default LogoutButton;
