/* eslint-disable canonical/sort-keys */
/* eslint-disable unicorn/prevent-abbreviations */
import { createEnv } from "@t3-oss/env-nextjs";
import { checkIsNonEmptyString } from "@/typeGuards";
import logger from "@/logger";
import {
  environmentVariablesClientConfig,
  type EnvironmentVariablesClientConfig,
} from "@/config/configSchema";
import defaultEnvironmentVariables from "@/config/defaultEnvironmentVariables";

function getEnvironmentVariable(
  key: keyof EnvironmentVariablesClientConfig,
  value: string | undefined,
): boolean | number | string {
  const defaultValue = defaultEnvironmentVariables[key];

  if (!checkIsNonEmptyString(value)) {
    if (typeof window === "undefined") {
      logger.warn(
        `${key} is not defined, using default value: ${defaultValue}`,
      );
    }

    return defaultValue;
  }

  if (typeof defaultValue === "number" && Number.isNaN(Number(value))) {
    return defaultValue;
  }

  return value;
}

const env = createEnv({
  client: environmentVariablesClientConfig,
  runtimeEnv: {
    NEXT_PUBLIC_ENCODED_RETURN_URL: getEnvironmentVariable(
      "NEXT_PUBLIC_ENCODED_RETURN_URL",
      process.env.NEXT_PUBLIC_ENCODED_RETURN_URL,
    ),
    NEXT_PUBLIC_GRAPHQL_PATH: getEnvironmentVariable(
      "NEXT_PUBLIC_GRAPHQL_PATH",
      process.env.NEXT_PUBLIC_GRAPHQL_PATH,
    ),
    NEXT_PUBLIC_GRAPHQL_REQUEST_RETRIES: getEnvironmentVariable(
      "NEXT_PUBLIC_GRAPHQL_REQUEST_RETRIES",
      process.env.NEXT_PUBLIC_GRAPHQL_REQUEST_RETRIES,
    ),
    NEXT_PUBLIC_ITEMISATION_PAGE_SIZE: getEnvironmentVariable(
      "NEXT_PUBLIC_ITEMISATION_PAGE_SIZE",
      process.env.NEXT_PUBLIC_ITEMISATION_PAGE_SIZE,
    ),
    NEXT_PUBLIC_LOG_LEVEL: getEnvironmentVariable(
      "NEXT_PUBLIC_LOG_LEVEL",
      process.env.NEXT_PUBLIC_LOG_LEVEL,
    ),
    NEXT_PUBLIC_NODE_ENV: getEnvironmentVariable(
      "NEXT_PUBLIC_NODE_ENV",
      process.env.NODE_ENV,
    ),
    NEXT_PUBLIC_PACKAGE_VERSION: getEnvironmentVariable(
      "NEXT_PUBLIC_PACKAGE_VERSION",
      process.env.NEXT_PUBLIC_PACKAGE_VERSION,
    ),

    NEXT_PUBLIC_SENTRY_ENABLE_NATIVE: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_ENABLE_NATIVE",
      process.env.NEXT_PUBLIC_SENTRY_ENABLE_NATIVE,
    ),
    NEXT_PUBLIC_SENTRY_ERROR_REPLAY_SAMPLE_RATE: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_ERROR_REPLAY_SAMPLE_RATE",
      process.env.NEXT_PUBLIC_SENTRY_ERROR_REPLAY_SAMPLE_RATE,
    ),
    NEXT_PUBLIC_SENTRY_SESSION_REPLAY_SAMPLE_RATE: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_SESSION_REPLAY_SAMPLE_RATE",
      process.env.NEXT_PUBLIC_SENTRY_SESSION_REPLAY_SAMPLE_RATE,
    ),
    NEXT_PUBLIC_SENTRY_DEBUG: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_DEBUG",
      process.env.NEXT_PUBLIC_SENTRY_DEBUG,
    ),
    NEXT_PUBLIC_SENTRY_DSN: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_DSN",
      process.env.NEXT_PUBLIC_SENTRY_DSN,
    ),
    NEXT_PUBLIC_SENTRY_ENABLED: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_ENABLED",
      process.env.NEXT_PUBLIC_SENTRY_ENABLED,
    ),
    NEXT_PUBLIC_SENTRY_ENV: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_ENV",
      process.env.NEXT_PUBLIC_SENTRY_ENV,
    ),
    NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE",
      process.env.NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE,
    ),
    NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: getEnvironmentVariable(
      "NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE",
      process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    ),
    NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB: getEnvironmentVariable(
      "NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB",
      process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID_WEB,
    ),
    NEXT_PUBLIC_GA_MEASUREMENT_ID_ANDROID: getEnvironmentVariable(
      "NEXT_PUBLIC_GA_MEASUREMENT_ID_ANDROID",
      process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID_ANDROID,
    ),
    NEXT_PUBLIC_GA_MEASUREMENT_ID_IOS: getEnvironmentVariable(
      "NEXT_PUBLIC_GA_MEASUREMENT_ID_IOS",
      process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID_IOS,
    ),
    NEXT_PUBLIC_OLARK_ID: getEnvironmentVariable(
      "NEXT_PUBLIC_OLARK_ID",
      process.env.NEXT_PUBLIC_OLARK_ID,
    ),
    NEXT_PUBLIC_AZURE_AUTH_PROVIDER_DOMAIN: getEnvironmentVariable(
      "NEXT_PUBLIC_AZURE_AUTH_PROVIDER_DOMAIN",
      process.env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_DOMAIN,
    ),
    NEXT_PUBLIC_AZURE_AUTH_PROVIDER_TENANT_DOMAIN: getEnvironmentVariable(
      "NEXT_PUBLIC_AZURE_AUTH_PROVIDER_TENANT_DOMAIN",
      process.env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_TENANT_DOMAIN,
    ),
    NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_POLICY_ID: getEnvironmentVariable(
      "NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_POLICY_ID",
      process.env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_POLICY_ID,
    ),
    NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_SIGN_IN_POLICY_ID:
      getEnvironmentVariable(
        "NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_SIGN_IN_POLICY_ID",
        process.env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_SIGN_IN_POLICY_ID,
      ),
    NEXT_PUBLIC_AZURE_AUTH_PROVIDER_CLIENT_ID: getEnvironmentVariable(
      "NEXT_PUBLIC_AZURE_AUTH_PROVIDER_CLIENT_ID",
      process.env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_CLIENT_ID,
    ),
    NEXT_PUBLIC_AZURE_AUTH_ANDROID_REDIRECT_URI: getEnvironmentVariable(
      "NEXT_PUBLIC_AZURE_AUTH_ANDROID_REDIRECT_URI",
      process.env.NEXT_PUBLIC_AZURE_AUTH_ANDROID_REDIRECT_URI,
    ),
    NEXT_PUBLIC_AZURE_AUTH_IOS_REDIRECT_URI: getEnvironmentVariable(
      "NEXT_PUBLIC_AZURE_AUTH_IOS_REDIRECT_URI",
      process.env.NEXT_PUBLIC_AZURE_AUTH_IOS_REDIRECT_URI,
    ),
  },
});

export default env;
