import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import {
  FaChevronDown,
  FaClose,
  FaSearch,
  Hamburger,
  NotchHead,
  NotificationBar,
  ProfilePic,
  DropdownMenu,
} from "@jt/ui";
import LoginButton from "../LoginButton";
import LogoutButton from "../LogoutButton";
import MenuItem from "./MenuItem";
import UserMenu from "./UserMenu";
import useAuth from "@/useAuth";
import config from "@/config";
import MobileNav from "@/components/layouts/mobile/MobileNav";

function DesktopHeader() {
  const [shouldShowMenu, setShouldShowMenu] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();

  function toggleMenu(shouldShow: boolean) {
    setShouldShowMenu(shouldShow);
    if (shouldShow) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }

  useEffect(() => {
    function onResize() {
      // web mobile version has a full screen menu toggle by hamburger menu
      // when screen size resize to desktop version, this menu should be hidden
      // we use window resize event to handle some mini tablet device,
      // which portrait mode is mobile screen size, landscape mode is tablet/desktop screen size
      if (window.innerWidth > 767) {
        toggleMenu(false);
      }
    }

    window.addEventListener("resize", onResize);

    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <header className={config.app.isNativeApp ? "hidden md:block" : ""}>
        <div className="fixed z-30 w-full">
          <div className="bg-gradient-to-r from-jt-blue via-jt-teal to-jt-lightgreen px-4 text-sm text-white">
            <NotchHead />
            <div className="flex justify-between">
              <div className="flex items-center gap-x-8">
                <div className="hidden h-full items-center gap-x-4 border-r border-r-white pr-4">
                  <div className="w-5 flex-none">
                    <Image
                      alt="Jersey Flag"
                      height="15"
                      src="/images/flag-jersey.png"
                      width="20"
                    />
                  </div>
                  Jersey
                  <FaChevronDown />
                </div>

                <a
                  className="hidden md:block"
                  href="https://www.jtglobal.com/jersey/personal/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Personal
                </a>

                <a
                  className="hidden md:block"
                  href="https://business.jtglobal.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Business
                </a>
              </div>

              <div className="my-2 flex items-center gap-x-4">
                <a
                  className="hidden hover:text-jt-blue md:block"
                  href="https://www.jtglobal.com/global/service-announcements/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Service Announcements
                </a>

                <Link
                  className="hidden hover:text-jt-blue md:block"
                  href="/user/manage/top-up"
                >
                  Top Up
                </Link>

                {isAuthenticated ? (
                  <LogoutButton />
                ) : (
                  <LoginButton
                    color="secondary"
                    label="Register&nbsp;&nbsp;/&nbsp;&nbsp;Log-in"
                    size="extrasmall"
                  />
                )}
              </div>
            </div>
          </div>
          <MobileNav
            className={shouldShowMenu ? "" : "hidden"}
            onClose={() => {
              toggleMenu(!shouldShowMenu);
            }}
          />
          <div
            className={`relative z-50 flex items-center justify-between drop-shadow ${
              shouldShowMenu ? "md:bg-white" : "bg-white"
            } px-4 py-2 md:py-0`}
          >
            <div
              aria-hidden="true"
              className="cursor-pointer md:hidden"
              onClick={() => {
                toggleMenu(!shouldShowMenu);
              }}
            >
              <Hamburger
                className={`text-black ${shouldShowMenu ? "hidden" : ""}`}
              />

              <FaClose
                className={`text-white ${shouldShowMenu ? "" : "hidden"}`}
              />
            </div>

            <Link
              className="relative h-[32px] w-[63px] md:h-[45px] md:w-[89px]"
              href="/"
            >
              <Image
                alt="JT Logo"
                className={`absolute size-full ${
                  shouldShowMenu ? "invisible md:visible" : ""
                }`}
                height="89"
                src="/images/JT_Logo@3x.png"
                width="60"
              />
              <Image
                alt="JT Logo"
                className={`absolute size-full ${
                  shouldShowMenu ? "md:invisible" : "invisible"
                }`}
                height="89"
                src="/images/JT_Logo_white@3x.png"
                width="60"
              />
            </Link>

            <nav className="flex items-center gap-2 font-bold">
              <MenuItem
                className="hidden items-center gap-x-2 md:flex lg:gap-x-4 xl:gap-x-8"
                itemClassName="text-center"
                linkClassName="block"
              />

              {isAuthenticated ? (
                <DropdownMenu
                  buttonChildren={<ProfilePic />}
                  className={shouldShowMenu ? "invisible" : ""}
                  data-cy="ProfileDropdownMenu"
                  menuChildren={<UserMenu isFeedbackDisabled />}
                />
              ) : null}
            </nav>

            <div
              aria-hidden="true"
              className="pointer-events-none hidden"
              onClick={() => {
                toggleMenu(true);
              }}
            >
              <FaSearch
                className={`${
                  shouldShowMenu ? "text-white" : "text-black"
                } size-7`}
              />
            </div>
          </div>
        </div>

        <div className="h-[111px] md:h-[124px]" />
      </header>
      <NotificationBar
        buttonLabel="Sign in for personalised deals"
        buttonLink="/login"
        canShow={false}
        className="z-20 md:pl-28"
        isApp={config.app.isNativeApp}
        message="We are aware of a service outage in St.Ouens. We have a team of engineers working to resolve the issue. For more info please visit our service status page."
      />
    </>
  );
}

export default DesktopHeader;
