/* graphql-codegen cannot support typescript import alias, so we have to disable this eslint rule and use relative path */
/* eslint-disable canonical/prefer-import-alias */
import { AccountType } from "./__generated__/graphql";
import type {
  ServiceResult,
  PrePaidMobileService,
} from "@/__generated__/graphql";

function checkIsNonEmptyString(aString: string | undefined): aString is string {
  return typeof aString === "string" && aString.length > 0;
}

function checkIsNonEmptyStringOrNumber(
  aString: number | string | undefined,
): boolean {
  return (
    (typeof aString === "string" && aString.length > 0) ||
    typeof aString === "number"
  );
}

function isPrepaidAccount(
  service: PrePaidMobileService | ServiceResult,
): service is PrePaidMobileService {
  return service.accountType === AccountType.Prepaid;
}

function isPostpaidAccount(
  service: PrePaidMobileService | ServiceResult,
): service is ServiceResult {
  return service.accountType === AccountType.Postpaid;
}

export {
  checkIsNonEmptyString,
  checkIsNonEmptyStringOrNumber,
  isPrepaidAccount,
  isPostpaidAccount,
};
