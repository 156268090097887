import { Capacitor } from "@capacitor/core";
import type { AzureAuthProvider } from "@/config/configSchema";
import env from "@/config/environmentVariables";

function getAzureAuthProviderConfig(): AzureAuthProvider {
  const azureAuthProviderDomain = env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_DOMAIN;
  const azureAuthProviderTenantDomain =
    env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_TENANT_DOMAIN;
  const azureAuthProviderSignUpPolicyId =
    env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_POLICY_ID;
  const azureAuthProviderSignUpSignInPolicyId =
    env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_SIGN_UP_SIGN_IN_POLICY_ID;
  const androidRedirectUri = env.NEXT_PUBLIC_AZURE_AUTH_ANDROID_REDIRECT_URI;
  const iosRedirectUri = env.NEXT_PUBLIC_AZURE_AUTH_IOS_REDIRECT_URI;

  const azureAuthProviderFullDomain = `https://${azureAuthProviderDomain}/${azureAuthProviderTenantDomain}`;
  const azureAuthProviderSignUpSignInPath = `${azureAuthProviderFullDomain}/${azureAuthProviderSignUpSignInPolicyId}`;
  const azureAuthProviderSignUpPath = `${azureAuthProviderFullDomain}/${azureAuthProviderSignUpPolicyId}`;

  return {
    clientId: env.NEXT_PUBLIC_AZURE_AUTH_PROVIDER_CLIENT_ID,
    domain: azureAuthProviderDomain,
    nativeRedirectUri:
      Capacitor.getPlatform() === "android"
        ? androidRedirectUri
        : iosRedirectUri,
    signUpPath: azureAuthProviderSignUpPath,
    signUpPolicyId: azureAuthProviderSignUpPolicyId,
    signUpSignInPath: azureAuthProviderSignUpSignInPath,
    signUpSignInPolicyId: azureAuthProviderSignUpSignInPolicyId,
    tenantDomain: azureAuthProviderTenantDomain,
  };
}

export default getAzureAuthProviderConfig;
