import type { EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "@/azureAuthConfig";
import NavigationAuthenticationClient from "@/components/pages/login/NavigationAuthenticationClient";
import logger from "@/logger";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.setNavigationClient(
  new NavigationAuthenticationClient(msalInstance),
);

/* eslint-disable unicorn/prefer-top-level-await, promise/always-return -- sample code from code from https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/typescript-sample/src/index.tsx  */
void msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload)
    ) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;

      logger.trace({ account, event }, "msalInstance.addEventCallback");

      msalInstance.setActiveAccount(account);
    }
  });
});

export default msalInstance;
