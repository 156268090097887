import { useState, useEffect } from "react";
import { App as CapacitorApp } from "@capacitor/app";
import Link from "next/link";
import { useRouter } from "next/router";
import { FaSearch, ProfilePic, NotchHead, FaArrowLeft, Dialog } from "@jt/ui";
import Image from "next/image";
import type { Route } from "nextjs-routes";

interface AppHeaderProperties {
  title: string;
  userNotifs?: number;
  hasHeaderTitle?: boolean;
  hasHero?: boolean;
  hasButtonBack?: boolean;
  hasSearch?: boolean;
  backPath?: Route;
}

export default function AppHeader({
  title,
  userNotifs = 0,
  hasHeaderTitle = true,
  hasHero = true,
  hasButtonBack = true,
  hasSearch = false,
  backPath,
}: AppHeaderProperties) {
  const router = useRouter();
  const [isTransparentHeader, setIsTransparentHeader] = useState(true);
  const [isConfirmExitOpen, setIsConfirmExitOpen] = useState(false);
  const shouldShowBackButton =
    router.query.root === "1" ? false : hasButtonBack;

  const shouldShowSearchButton = !shouldShowBackButton && hasSearch;
  const shouldShowLogo =
    !shouldShowSearchButton && !shouldShowBackButton && !hasHeaderTitle;

  function onBackClick(): void {
    if (backPath) {
      void router.push(backPath);
    } else {
      router.back();
    }
  }

  useEffect(() => {
    function onScroll() {
      // app mobile version will show a white header when scroll down
      // therefore, when window is scroll to top, this white header will become transparent
      setIsTransparentHeader(window.scrollY <= 0);
    }
    function onResize() {
      // app mobile version will show a white header when scroll down
      // addEventListener for window scroll event only in mobile screen effect
      // removeEventListener when screen size is large
      // we use window resize event to handle some mini tablet device,
      // which portrait mode is mobile screen size, landscape mode is tablet/desktop screen size
      window.removeEventListener("scroll", onScroll);
      if (window.innerWidth <= 767) {
        window.addEventListener("scroll", onScroll);
      }
    }
    function onHardwareBackPress() {
      if (shouldShowBackButton) {
        onBackClick();
      } else {
        setIsConfirmExitOpen(true);
      }
    }
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);
    void CapacitorApp.addListener("backButton", onHardwareBackPress);
    onResize();

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onResize);
      void CapacitorApp.removeAllListeners();
    };
  }, [shouldShowBackButton]);

  function onProfilePicClick(): void {
    void router.push("/user/profile-menu");
  }

  const headerClass = isTransparentHeader
    ? "absolute"
    : "fixed drop-shadow-[0_2px_4px_rgba(16,24,40,0.1)]";
  const textColor =
    isTransparentHeader && hasHero ? "text-white" : "text-jt-blue";
  const iconColor = isTransparentHeader ? "text-white" : "text-jt-teal";

  return (
    <>
      <header
        className={`left-0 top-0 z-50 w-full py-5 md:hidden ${headerClass}`}
      >
        <NotchHead />

        <div
          className={`absolute left-0 top-0 size-full bg-white transition-opacity duration-300 ${
            isTransparentHeader ? "opacity-0" : "opacity-100"
          }`}
        />

        <div className="relative flex size-full flex-row items-center gap-1 px-4">
          {shouldShowBackButton ? (
            <button
              className="relative flex size-10 flex-col items-center justify-center rounded-full border border-neutral-100 bg-secondary text-xl text-white drop-shadow-md"
              onClick={onBackClick}
              type="button"
            >
              <FaArrowLeft />
            </button>
          ) : null}

          {shouldShowSearchButton ? (
            <Link className="pointer-events-none invisible" href="/">
              <div
                className={`flex size-10 flex-col items-center justify-center rounded-full text-xl${iconColor}`}
              >
                <FaSearch />
              </div>
            </Link>
          ) : null}

          {shouldShowLogo ? (
            <div className="md:hidden">
              <Image
                alt="JT Logo"
                className="inline"
                height="68"
                src="/images/JT_Logo.png"
                width="100"
              />
            </div>
          ) : null}

          {hasHeaderTitle ? (
            <>
              <div className="grow" />

              <div className={`text-xl font-semibold ${textColor}`}>
                {title}
              </div>
            </>
          ) : null}

          <div className="grow" />

          <div className="relative">
            <div className="absolute inset-1 rounded-full bg-white" />
            <ProfilePic
              className="cursor-pointer border border-neutral-100"
              onClick={onProfilePicClick}
            >
              {userNotifs > 0 && (
                <div className="absolute -right-2 -top-1 flex size-1/2 flex-col items-center justify-center rounded-full border border-neutral-100 bg-jt-blue text-center text-xs">
                  {userNotifs > 9 ? "9+" : userNotifs}
                </div>
              )}
            </ProfilePic>
          </div>
        </div>
      </header>

      <div className="h-20 md:hidden" />
      {isConfirmExitOpen ? (
        <Dialog
          isOpen
          onCancelClick={() => {
            setIsConfirmExitOpen(false);
          }}
          onClose={() => {
            setIsConfirmExitOpen(false);
          }}
          onOKClick={() => {
            void CapacitorApp.exitApp();
          }}
          title="Confirm to exit?"
        />
      ) : null}
    </>
  );
}
