// reference: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md#inavigationclient-implementation
// reference: https://stackoverflow.com/questions/76158436/how-to-implement-azure-active-directory-msal-in-ionic-react-so-it-works-when-t

import type { IPublicClientApplication } from "@azure/msal-browser";
import { NavigationClient } from "@azure/msal-browser";
import { Browser } from "@capacitor/browser";
import { App, type URLOpenListenerEvent } from "@capacitor/app";
import config from "@/config";
import logger from "@/logger";

export default class NavigationAuthenticationClient extends NavigationClient {
  constructor(private msalInstance: IPublicClientApplication) {
    super();
  }

  async navigateExternal(
    url: string,
    options: { noHistory: boolean },
  ): Promise<boolean> {
    if (config.app.isNativeApp) {
      logger.debug(
        { options, url },
        "navigateExternal for msal.js on native app",
      );

      const formatValidRedirectUri = url.replace("capacitor", "https");

      await Browser.open({
        url: formatValidRedirectUri,
        windowName: "",
      });

      void App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        void (async () => {
          logger.debug(event, "appUrlOpen event received.");

          if (
            event.url.includes("state") &&
            (event.url.includes("code") || event.url.includes("error"))
          ) {
            // login success or user cancel
            logger.info("handleRedirectPromise");

            const [, responseString] = event.url.split("#");

            await this.msalInstance.handleRedirectPromise(responseString);
          } else {
            // user logout
            logger.info("handleLogout");

            await Browser.close();

            window.location.replace("/");

            return;
          }

          await Browser.close();
        })();
      });

      return false;
    } else {
      if (options.noHistory) {
        window.location.replace(url);
      } else {
        window.location.assign(url);
      }

      return true;
    }
  }
}
