import "@/styles/globals.css";
import "@jt/ui/dist/style.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { type ReactElement, type JSX } from "react";
import type { NextPage } from "next";
import RootLevelContextsContainer from "@/components/contexts/RootLevelContextsContainer";
import AppSwitch from "@/components/AppSwitch";

type AppPropertiesWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

type NextPageWithLayout = NextPage & {
  getLayout?: (_page: ReactElement) => JSX.Element;
};

export function App({ Component, pageProps }: AppPropertiesWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          name="viewport"
        />
      </Head>
      <RootLevelContextsContainer>
        <AppSwitch>{getLayout(<Component {...pageProps} />)}</AppSwitch>
      </RootLevelContextsContainer>
    </>
  );
}

export type { NextPageWithLayout };

export default App;
