import Image from "next/image";

interface DesktopFooterBarProperties {
  className?: string;
}

function DesktopFooterBar({ className = "" }: DesktopFooterBarProperties) {
  const year = new Date().getFullYear();

  return (
    <div className={`relative hidden w-full md:block ${className}`}>
      <Image
        alt="JT Logo"
        className="absolute bottom-[54px] right-0"
        height="94"
        src="/images/footerLogo.png"
        width="164"
      />

      <div
        className="flex items-center justify-between bg-jt-blue bg-right bg-no-repeat text-sm text-white"
        style={{ backgroundImage: "url(/images/footerBar.png)", height: 54 }}
      >
        <div className="ml-8 @7xl:ml-16">
          <a
            className="mr-8 hover:text-jt-blue"
            href="https://www.jtglobal.com/global/terms-conditions"
            rel="noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>

          <a
            className="mr-8 hover:text-jt-blue"
            href="https://www.jtglobal.com/global/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>

        <div className="mr-8">
          &copy; {year} Jersey Telecoms. All rights reserved.
        </div>
      </div>
    </div>
  );
}
export default DesktopFooterBar;
