import { useState, useEffect, type JSX } from "react";
import { useRouter } from "next/router";
import { Transition, animated } from "react-spring";
import config from "@/config";

export default function PageAnimation({
  children,
  hasTransition,
}: {
  children: JSX.Element;
  hasTransition: boolean;
}) {
  const router = useRouter();
  const [from, setFrom] = useState<{ transform: string }>({
    transform: "translateX(100%)",
  });

  function handlePopState() {
    setFrom({ transform: "translateX(-100%)" });
  }

  useEffect(() => {
    setFrom({ transform: "translateX(100%)" });
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [router.pathname]);

  if (!config.app.isNativeApp || !hasTransition) return children;

  const items = [
    {
      children,
      id: router.route,
    },
  ];

  return (
    <Transition
      enter={{
        backgroundColor: "#f6f9fe",
        position: "relative",
        transform: "translateX(0%)",
      }}
      from={from}
      initial={{ transform: "translateX(0%)" }}
      items={items}
      keys={(item: (typeof items)[number]) => item.id}
      leave={{
        position: "absolute",
        transform: "translateX(0%)",
      }}
    >
      {(styles, { children: animatedChildren }, key) => (
        <animated.div
          key={key.item.id}
          style={{
            ...styles,
            height: "100%",
            width: "100%",
          }}
        >
          {animatedChildren}
        </animated.div>
      )}
    </Transition>
  );
}
