import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

function useAzure() {
  const { instance, inProgress } = useMsal();
  const isLoading =
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect;
  const isAuthenticated = useIsAuthenticated();
  const user = instance.getActiveAccount();

  return { isAuthenticated, isLoading, user };
}
export default useAzure;
