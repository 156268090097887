/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { assertEvent, assign, setup } from "xstate";
import type {
  TopupMachineContext,
  TopupMachineEvents,
  TopupMachineInput,
} from "./types";
import logger from "@/logger";

const topupMachine = setup({
  actions: {
    assignBasketWithNewItemToContext: assign({
      basketItems: ({ context, event }) => {
        assertEvent(event, "ADD_TO_BASKET");

        const basketItems = [...context.basketItems, event.data];

        return basketItems;
      },
      totalValueInBasketInPounds: ({ context, event }) => {
        assertEvent(event, "ADD_TO_BASKET");

        const totalValueInBasketInPounds =
          context.totalValueInBasketInPounds + event.data.amountInPounds;

        return totalValueInBasketInPounds;
      },
    }),
    assignBasketWithRemovedItemToContext: assign({
      basketItems: ({ context, event }) => {
        assertEvent(event, "REMOVE_FROM_BASKET");

        const { basketItems } = context;

        basketItems.splice(event.data, 1);

        return basketItems;
      },
      totalValueInBasketInPounds: ({ context, event }) => {
        assertEvent(event, "REMOVE_FROM_BASKET");

        const totalValueInBasketInPounds = context.basketItems.reduce(
          (accumulator, item) => accumulator + item.amountInPounds,
          0,
        );

        return totalValueInBasketInPounds;
      },
    }),
    assignOffersToContext: assign({
      availableOffers: ({ event }) => {
        assertEvent(event, "xstate.done.actor.fetchOffers");

        return event.output.topup;
      },
    }),
    assignOtherNumberToContext: assign({
      otherNumber: ({ event }) => {
        assertEvent(event, "SUBMIT");

        return event.data;
      },
    }),
    assignPhoneNumberToContext: assign({
      phoneNumber: ({ event }) => {
        assertEvent(event, "SELECT_SERVICE");

        return event.data;
      },
    }),
    clearBasket: assign({
      basketItems: () => {
        return [];
      },
      totalValueInBasketInPounds: () => {
        return 0;
      },
    }),
    redirectToCheckout: () => {
      logger.info(
        "Placeholder action for redirecting to checkout page. This should be overwritten by the context provider. If you see this, something went wrong.",
      );
    },
    redirectToOtherNumber: () => {
      logger.info(
        "Placeholder action for redirecting to other number page. This should be overwritten by the context provider. If you see this, something went wrong.",
      );
    },
    redirectToTopup: () => {
      logger.info(
        "Placeholder action for redirecting to topup page. This should be overwritten by the context provider. If you see this, something went wrong.",
      );
    },
    showError: ({ event }) => {
      assertEvent(event, ["xstate.error.actor", "ERROR"]);

      logger.error(event.output);
    },
  },
  guards: {
    basketEmpty: ({ context }) => {
      return context.basketItems.length === 0;
    },
    phoneNumberUndefined: ({ context }) => {
      return context.phoneNumber === undefined;
    },
  },
  types: {
    context: {} as TopupMachineContext,
    events: {} as TopupMachineEvents,
    input: {} as TopupMachineInput,
  },
}).createMachine({
  context: ({ input }) => ({
    availableOffers: undefined,
    basketItems: [],
    otherNumber: undefined,
    phoneNumber: input.phoneNumber,
    totalValueInBasketInPounds: 0,
  }),
  description:
    "This Machine should only be rendered on pages where topups are being done. Ideally this will not re-render while you're still in the topup experience.",
  entry: {
    type: "redirectToTopup",
  },
  id: "TopUpMachine",
  states: {
    SelectingService: {
      initial: "ServiceSelected",
      states: {
        CheckingPrepaidNumberExists: {
          invoke: {
            // @ts-expect-error - error due to some xstate types changes in v5.14.0
            id: "checkPrepaidNumberExists",
            input: ({
              context,
            }: {
              context: TopupMachineContext;
            }): { phoneNumber: string | undefined } => ({
              phoneNumber: context.otherNumber,
            }),
            src: "checkPrepaidNumberExists",
          },
          on: {
            ERROR: {
              actions: { type: "showError" },
              target: "OtherNumberInputScreen",
            },
            SELECT_SERVICE: {
              actions: {
                type: "assignPhoneNumberToContext",
              },
              target: "ServiceSelected",
            },
          },
        },
        OtherNumberInputScreen: {
          on: {
            LEAVE_OTHER_NUMBER: {
              target: "ServiceSelected",
            },
            SUBMIT: {
              actions: {
                type: "assignOtherNumberToContext",
              },
              target: "CheckingPrepaidNumberExists",
            },
          },
        },
        ServiceSelected: {
          always: {
            actions: {
              type: "redirectToOtherNumber",
            },
            guard: "phoneNumberUndefined",
            target: "OtherNumberInputScreen",
          },
          on: {
            OTHER_NUMBER: {
              actions: {
                type: "redirectToOtherNumber",
              },
              target: "OtherNumberInputScreen",
            },
            SELECT_SERVICE: {
              actions: {
                type: "assignPhoneNumberToContext",
              },
              target: "ServiceSelected",
            },
          },
        },
      },
    },
    Topup: {
      initial: "FetchOffers",
      on: {
        RESET: {
          actions: {
            type: "clearBasket",
          },
          target: "#TopUpMachine.Topup.Basket",
        },
      },
      states: {
        Basket: {
          initial: "SelectingTopupOffers",
          on: {
            CHECKOUT: {
              actions: { type: "redirectToCheckout" },
              target: "Checkout",
            },
            SELECT_SERVICE: {
              actions: {
                type: "clearBasket",
              },
              target: "#TopUpMachine.Topup.Basket.SelectingTopupOffers",
            },
          },
          states: {
            SelectingTopupOffers: {
              on: {
                ADD_TO_BASKET: [
                  {
                    actions: {
                      type: "assignBasketWithNewItemToContext",
                    },
                    guard: {
                      type: "basketEmpty",
                    },
                    target: "ShowingBasketFlyout",
                  },
                  {
                    actions: {
                      type: "assignBasketWithNewItemToContext",
                    },
                    target: "SelectingTopupOffers",
                  },
                ],
                OPEN_BASKET: {
                  target: "ShowingBasketFlyout",
                },
              },
            },
            ShowingBasketFlyout: {
              on: {
                CLOSE_BASKET: {
                  target: "SelectingTopupOffers",
                },
                REMOVE_FROM_BASKET: {
                  actions: {
                    type: "assignBasketWithRemovedItemToContext",
                  },
                  target: "ShowingBasketFlyout",
                },
              },
            },
          },
        },
        Checkout: {
          on: {
            LEAVE_CHECKOUT: {
              actions: { type: "redirectToTopup" },
              target: "Basket",
            },
          },
        },
        FailedGettingOffers: {},
        FetchOffers: {
          invoke: {
            // @ts-expect-error - error due to some xstate types changes in v5.14.0
            id: "fetchOffers",
            input: undefined,
            onDone: {
              actions: {
                type: "assignOffersToContext",
              },
              target: "Basket",
            },
            onError: {
              actions: { type: "showError" },
              target: "FailedGettingOffers",
            },
            src: "getTopupOffers",
          },
        },
      },
    },
  },
  type: "parallel",
});

export default topupMachine;
