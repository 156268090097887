import { MsalProvider } from "@azure/msal-react";
import { Error } from "@jt/ui";
import { ErrorBoundary } from "@sentry/nextjs";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { ReactNode } from "react";
import { GraphQLProvider } from "./GraphQLContext";
import { MachineUtilProvider } from "./MachineUtil";
import AppAnalytics from "@/components/AppAnalytics";
import { CookiesProvider } from "@/components/machines/cookiesMachine/CookiesMachineContext";
import queryClient from "@/queryClient";
import msalInstance from "@/msalInstance";
import { ToastProvider } from "@/components/contexts/toast";

function handleLoginClick() {
  if (msalInstance.getActiveAccount()) {
    void msalInstance.logoutRedirect();
  } else {
    window.location.href = "/";
  }
}

function RootLevelContextsContainer({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary
      fallback={<Error isInline onLoginClick={handleLoginClick} />}
    >
      <MachineUtilProvider>
        <ToastProvider>
          <MsalProvider instance={msalInstance}>
            <CookiesProvider>
              <AppAnalytics />
              <QueryClientProvider client={queryClient}>
                <GraphQLProvider>
                  {children}
                  <ReactQueryDevtools initialIsOpen={false} />
                </GraphQLProvider>
              </QueryClientProvider>
            </CookiesProvider>
          </MsalProvider>
        </ToastProvider>
      </MachineUtilProvider>
    </ErrorBoundary>
  );
}

export default RootLevelContextsContainer;
