import type { JSX } from "react";
import { useRouter } from "next/router";
import Admin from "./Admin";
import AuthenticatedMyJtApp from "./AuthenticatedMyJtApp";

function AppSwitch({ children }: { children: JSX.Element }) {
  const { pathname } = useRouter();
  const isAdminUrl = pathname.startsWith("/admin");
  const isBlankPage = pathname.startsWith("/blank");

  switch (true) {
    case isAdminUrl: {
      return <Admin>{children}</Admin>;
    }

    case isBlankPage: {
      return children;
    }

    default: {
      return <AuthenticatedMyJtApp>{children}</AuthenticatedMyJtApp>;
    }
  }
}

export default AppSwitch;
