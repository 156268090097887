import UserAccountMachineContext from "@/components/machines/userAccountMachine/UserAccountMachineContext";

function useAccountType(): AccountType {
  const state = UserAccountMachineContext.useSelector((s) => s);

  if (state.matches("ServicesLoaded.ServicesConfiguration.SinglePrepaid")) {
    return "SinglePrepaid";
  } else if (
    state.matches("ServicesLoaded.ServicesConfiguration.OnlyPostpaid")
  ) {
    return "OnlyPostpaid";
  } else if (
    state.matches("ServicesLoaded.ServicesConfiguration.MultiplePrepaid")
  ) {
    return "MultiplePrepaid";
  } else if (
    state.matches("ServicesLoaded.ServicesConfiguration.PreAndPostPaid")
  ) {
    return "PreAndPostPaid";
  }

  return "unknown";
}

export type AccountType =
  | "MultiplePrepaid"
  | "OnlyPostpaid"
  | "PreAndPostPaid"
  | "SinglePrepaid"
  | "unknown";
export default useAccountType;
