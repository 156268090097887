import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  Paragraph,
} from "@jt/ui";

function ColumnHeader({ label }: { label: string }) {
  return (
    <Paragraph
      className="mb-1.5 font-bold uppercase text-jt-teal @3xl:whitespace-nowrap supports-[not(container-type:inline-size)]:md:whitespace-nowrap"
      size="small"
    >
      {label}
    </Paragraph>
  );
}

function DesktopFooter() {
  return (
    <div className="relative hidden md:block">
      <div className="grid grid-cols-3 justify-between gap-y-6 bg-white p-8 text-sm @3xl:flex @7xl:pl-16 @7xl:pr-12 supports-[not(container-type:inline-size)]:md:flex supports-[not(container-type:inline-size)]:xl:pl-16 supports-[not(container-type:inline-size)]:xl:pr-12">
        <div className="mr-4 @3xl:mr-8 @5xl:mr-16">
          <ColumnHeader label="Company Info" />
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.jtglobal.com/about-us/"
                rel="noreferrer"
                target="_blank"
              >
                About Us
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/our-people/"
                rel="noreferrer"
                target="_blank"
              >
                Our People
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/our-history/"
                rel="noreferrer"
                target="_blank"
              >
                Our History
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/community-giving/"
                rel="noreferrer"
                target="_blank"
              >
                Community Giving
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/sustainability/"
                rel="noreferrer"
                target="_blank"
              >
                Sustainability
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/jt-help/jersey/customer-promise"
                rel="noreferrer"
                target="_blank"
              >
                Customer Promise
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/jersey/latest-news"
                rel="noreferrer"
                target="_blank"
              >
                Latest News
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/customer-satisfaction-results/"
                rel="noreferrer"
                target="_blank"
              >
                Customer Satisfaction Results
              </a>
            </li>
          </ul>
        </div>

        <div className="mr-4 @3xl:mr-8 @5xl:mr-16">
          <ColumnHeader label="Home Service" />
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.jtglobal.com/jersey/jt-one/"
                rel="noreferrer"
                target="_blank"
              >
                Bundle and save with JT One
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/jersey/pay-monthly-plans/"
                rel="noreferrer"
                target="_blank"
              >
                Pay Monthly Mobile
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/jersey/payg-plans/"
                rel="noreferrer"
                target="_blank"
              >
                Pay As You Go Mobile
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/roaming/"
                rel="noreferrer"
                target="_blank"
              >
                Roaming
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/jersey/mobile-broadband/"
                rel="noreferrer"
                target="_blank"
              >
                Mobile Broadband
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/jersey/fibre-broadband/"
                rel="noreferrer"
                target="_blank"
              >
                Home Broadband
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/jt-total-wi-fi/"
                rel="noreferrer"
                target="_blank"
              >
                JT Total WiFi
              </a>
            </li>
          </ul>
        </div>

        <div className="mr-4 @3xl:mr-8 @5xl:mr-16">
          <ColumnHeader label="Enterprise Solutions" />
          <ul className="space-y-2">
            <li>
              <a
                href="https://business.jtglobal.com/network/"
                rel="noreferrer"
                target="_blank"
              >
                Network Services
              </a>
            </li>

            <li>
              <a
                href="https://business.jtglobal.com/managed-services/"
                rel="noreferrer"
                target="_blank"
              >
                Managed Services
              </a>
            </li>

            <li>
              <a
                href="https://business.jtglobal.com/voice-services/"
                rel="noreferrer"
                target="_blank"
              >
                Voice Services
              </a>
            </li>

            <li>
              <a
                href="https://business.jtglobal.com/cloud/"
                rel="noreferrer"
                target="_blank"
              >
                Cloud Services
              </a>
            </li>

            <li>
              <a
                href="https://business.jtglobal.com/cybersecurity/"
                rel="noreferrer"
                target="_blank"
              >
                Cyber Security
              </a>
            </li>

            <li>
              <a
                href="https://business.jtglobal.com/egaming"
                rel="noreferrer"
                target="_blank"
              >
                eGaming Services
              </a>
            </li>

            <li>
              <a
                href="https://business.jtglobal.com/mobile"
                rel="noreferrer"
                target="_blank"
              >
                Mobile Services
              </a>
            </li>
          </ul>
        </div>

        <div className="mr-4 @3xl:mr-8 @5xl:mr-16">
          <ColumnHeader label="More Sites" />
          <ul className="space-y-2">
            <li>
              <a
                href="https://www.jtrewards.com/"
                rel="noreferrer"
                target="_blank"
              >
                JT Rewards
              </a>
            </li>

            <li>
              <a
                href="https://www.jtdirectory.com/"
                rel="noreferrer"
                target="_blank"
              >
                JT Directory
              </a>
            </li>

            <li>
              <a
                href="https://www.jtdirectory.com/classifieds"
                rel="noreferrer"
                target="_blank"
              >
                JT Insight
              </a>
            </li>

            <li>
              <a href="https://jtquote.com/" rel="noreferrer" target="_blank">
                JT Quote
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/jtpresents/"
                rel="noreferrer"
                target="_blank"
              >
                JT Present
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/access-wholesale/"
                rel="noreferrer"
                target="_blank"
              >
                Wholesale portal
              </a>
            </li>
          </ul>
        </div>

        <div className="mr-4 @3xl:mr-8 @5xl:mr-16">
          <ColumnHeader label="Join Us" />
          <ul className="space-y-2">
            <li>
              <a
                href="https://jtglobal.pinpointhq.com/"
                rel="noreferrer"
                target="_blank"
              >
                Careers at JT
              </a>
            </li>

            <li>
              <a
                href="https://www.jtglobal.com/global/jt-voice/"
                rel="noreferrer"
                target="_blank"
              >
                Join Our Focus Group
              </a>
            </li>
          </ul>
        </div>

        <div className="mr-4 @3xl:mr-8 @5xl:mr-16">
          <ColumnHeader label="Follow Us" />

          <ul className="leading-7">
            <li>
              <a
                className="flex items-center"
                href="https://www.instagram.com/jtsocial/"
                rel="noreferrer"
                target="_blank"
              >
                <FaInstagram className="mr-2 text-lg text-[#000100]" />
                Instagram
              </a>
            </li>

            <li>
              <a
                className="flex items-center"
                href="https://twitter.com/jt_business"
                rel="noreferrer"
                target="_blank"
              >
                <FaTwitter className="mr-2 text-lg text-[#1da1f2]" />
                Twitter
              </a>
            </li>

            <li>
              <a
                className="flex items-center"
                href="https://www.facebook.com/JTsocial"
                rel="noreferrer"
                target="_blank"
              >
                <FaFacebook className="mr-2 text-lg text-[#1877f2]" />
                Facebook
              </a>
            </li>

            <li>
              <a
                className="flex items-center"
                href="https://www.linkedin.com/company/12735/"
                rel="noreferrer"
                target="_blank"
              >
                <FaLinkedin className="mr-2 text-lg text-[#0a66c2]" />
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DesktopFooter;
